import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import '../../assets/scss/MyPledges.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Button, Card, Form, FormControl } from 'react-bootstrap';
import Image1 from '../../assets/img/campaign.jpeg'
import Image2 from '../../assets/img/campaign.png'
import Coin from '../../assets/img/coin-stack.png'
import User from '../../assets/img/user.png'
import User1 from '../../assets/img/user_1.png'
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import GreenHeart from '../../assets/img/heart_green.png'
import OrangeHeart from '../../assets/img/heart_orange.png'
import Comment from '../../assets/img/comment.png'
import LiveChat from '../../assets/img/live-chat.png'
import PlusCircle from '../../assets/img/plus.png'
import MakePledge from './MakePledge';

function MyPledges({ pledgeCount }) {

    // get the data 
    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token to get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;
    const [pledgeData, setPledgeData] = useState([])
    const [churchUser, setChurchUser] = useState({})

    useEffect(() => {
        // Fetch church information for the user
        serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
            .then((res) => {
                setChurchUser(res.data.results[0]);

                // Check if the church id is available
                const churchId = res.data.results.length !== 0 ? parseInt(res.data.results[0].church) : null;

                // Fetch pledges based on condition
                serverUrl.get(`/pledge/active-pledges?user_id=${userId}`, config)
                    .then((res) => {
                        setPledgeData(res.data.results);

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [pledgeCount]);

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null);
    };

    const filteredData = pledgeData.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    const handleEditPledge = () => {
        localStorage.setItem('modalSet', 'true')
    }
    const [pledgeModal, makePledgeModal] = useState(false)

    const handleAddPledge = () => {
        localStorage.setItem('modalAddSet', 'true')
    }



    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 50000
    };
    const goBack = () => {
        window.history.back();
    };
    return (
        <>

            <div className='activePledges'>
                <div className='d-flex justify-content-between'>
                    <h2 className='text-danger text-start'>My Active Pledges ({pledgeCount})</h2>
                    <Form className="searchBar">
                        <FormControl type="text" placeholder="Search By Name" onChange={handleSearch} />
                    </Form>
                </div>
                <p className='text-muted text-center' style={{ fontSize: "12px" }}>Tap to Select a Pledge to Redeem it</p>
                <div className='sliderForCampaign'>
                    {filteredData.map((campaign, index) => (
                        <Link to={`/pledge/${campaign.payment_code}/`} className='text-decoration-none text-dark '>
                            <Card key={index} className='activePledgesCards'>
                                <div className='profileUserImage'>
                                    {campaign.pledge_type === 'monetary' ?
                                        <span style={{ textTransform: "capitalize", backgroundColor: "#2ecc71", color: "white", padding: "0.2rem", borderRadius: "2px", marginRight: "4px" }}>{campaign.pledge_type}</span>

                                        :
                                        <span style={{ textTransform: "capitalize", backgroundColor: "#3498db", color: "white", padding: "0.2rem", borderRadius: "2px", marginRight: "4px" }}>{campaign.pledge_type}</span>

                                    }
                                    <div style={{ }}>
                                        {campaign.profile ?
                                            <Image src={campaign.profile_image} />
                                            :
                                            <Image src={campaign.user_image} />
                                        }

                                    </div>

                                </div>
                                <Image className='topImageCampaign' src={campaign.campaign_image} />
                                <div className='nameofUserHere'>
                                    <div className='topSpan'>
                                        {campaign.status === 'unfulfilled' ?
                                            <p className=' text-start' style={{ lineHeight: "0", textTransform: "capitalize", backgroundColor: "#e74c3c", color: "white", padding: "0.6rem", borderRadius: "10px", fontSize: "10px", width: "fit-content" }}>{campaign.status}</p>

                                            : campaign.status === 'partial' ?
                                                <p className=' text-start' style={{ lineHeight: "0", textTransform: "capitalize", backgroundColor: "#f39c12", color: "white", padding: "0.6rem", borderRadius: "10px", fontSize: "10px", width: "fit-content" }}>{campaign.status}</p>

                                                :
                                                <p className=' text-start' style={{ lineHeight: "0", textTransform: "capitalize", backgroundColor: "#2ecc71", color: "white", padding: "0.6rem", borderRadius: "10px", fontSize: "10px", width: "fit-content" }}>{campaign.status}</p>

                                        }
                                    </div>
                                    {campaign.profile ?
                                        <p>{campaign.profile_name}</p>
                                        :
                                        <p>Myself Payment</p>
                                    }
                                </div>
                                <div className='pledegActiveDisplayData ms-2'>

                                    <p className='text-start fw-bold' style={{ lineHeight: "0", fontSize: "0.7rem", marginBottom: "0.6rem" }}>
                                        <i className='bi bi-calendar-week text-danger me-2'></i>
                                        {campaign.remaining_months === 1 ?
                                            <span className='fw-bold'>{campaign.remaining_months} month to go</span>
                                            : campaign.remaining_months === 0 ?
                                                <span className='fw-bold'>~less than one month to go</span>
                                                :
                                                <span className='fw-bold'>{campaign.remaining_months} month(s) to go</span>
                                        }
                                    </p>



                                    {/* get the pledge type if the amount is nan then display the description   */}
                                    {campaign.pledge_type === 'monetary' ?
                                        campaign.currency !== 'KSH' ?
                                            <div className='d-flex'>
                                                <i className='bi bi-cash-stack text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                <p className='text-start fw-bolder' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.dollar_amount).toLocaleString('en-US')}</p>
                                            </div>
                                            :
                                            <div className='d-flex'>
                                                <i className='bi bi-cash-stack text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                <p className='text-start fw-bolder' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.pledge_amount).toLocaleString('en-US')}</p>


                                            </div>
                                        :
                                        campaign.pledge_type === 'in-kind' ? (
                                            <>
                                                {campaign.estimated_amount && campaign.in_kind_name ? (
                                                    <>
                                                        <div className='d-flex'>
                                                            <i className='bi bi-cash-stack text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                            {campaign.currency !== 'KSH' ? (
                                                                <p className='text-start' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.dollar_amount).toLocaleString('en-US')}</p>
                                                            ) : (
                                                                <p className='text-start' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.estimated_amount).toLocaleString('en-US')}</p>
                                                            )}
                                                        </div>
                                                        <div className='d-flex'>
                                                            <i className='bi bi-chat-left-text text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                            <p className='text-start truncate-text-description' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.in_kind_name}</p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className='d-flex'>
                                                        {campaign.estimated_amount ? (
                                                            <>
                                                                <i className='bi bi-cash-stack text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                                {campaign.currency !== 'KSH' ? (
                                                                    <p className='text-start' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.dollar_amount).toLocaleString('en-US')}</p>
                                                                ) : (
                                                                    <p className='text-start' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.estimated_amount).toLocaleString('en-US')}</p>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className='bi bi-chat-left-text text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                                <p className='text-start truncate-text-description' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.in_kind_name}</p>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )


                                    }
                                    {campaign.contributed_amount === 0 ? <p style={{ height: "1vh" }}></p> : (
                                        <div className='d-flex' style={{ width: "2vw" }}>
                                            {campaign.status === 'partial' && (
                                                <div className='d-flex'>
                                                    <Image className='me-2' style={{ marginTop: "-0.2rem", width: "1vw", height: "75%", marginLeft: "-3px" }} src={OrangeHeart} />
                                                    <p className='b'></p>
                                                    <p className='text-start fw-bold' style={{ fontSize: "0.7rem", marginBottom: "0.2rem", color: campaign.status === 'unfulfilled' ? '#e74c3c' : campaign.status === 'partial' ? '#f39c12' : '#2ecc71' }}>
                                                        {campaign.currency}.{parseInt(campaign.contributed_amount).toLocaleString('en-US')}
                                                    </p>
                                                </div>
                                            )}

                                        </div>
                                    )
                                    }



                                </div>
                                <div className='d-flex justify-content-end' style={{ position: "absolute", bottom: "4px", right: "5px" }}>
                                    {campaign.status === 'partial' ?
                                        <span className='btn btn-danger text-white btn-sm' style={{ display: "inline", padding: "3px", fontSize: "10px" }}>
                                            <Link style={{ display: "inline", padding: "3px", fontSize: "10px" }} to={`/pledge/${campaign.payment_code}`} className='text-white text-decoration-none'>Redeem</Link>

                                        </span>
                                        : campaign.status === 'fulfilled' ?
                                            <span className='btn btn-dark text-white btn-sm' style={{ display: "inline", padding: "3px", fontSize: "10px" }}>
                                                <Link style={{ display: "inline", padding: "3px", fontSize: "10px" }} to={`/pledge/${campaign.payment_code}`} className='text-white text-decoration-none'>Edit</Link>

                                            </span>
                                            :
                                            <span className='btn btn-danger text-white btn-sm' style={{ display: "inline", padding: "3px", fontSize: "10px" }}>
                                                <Link style={{ display: "inline", padding: "3px", fontSize: "10px" }} to={`/pledge/${campaign.payment_code}`} className='text-white text-decoration-none'>Redeem</Link>

                                            </span>

                                    }
                                </div>
                            </Card>

                        </Link>


                    ))}
                </div>

                {/* <Slider {...settings} className='sliderForCampaign'>
                    {campaignsData.map((campaign, index) => (
                        <Link to='/pledge' className='text-decoration-none text-dark sliderForCampaign'>
                            <Card key={index} className='activePledgesCards'>
                                <div className='profileUserImage'>
                                    <Image src={campaign.user_image} />

                                </div>
                                <Image src={campaign.image} />
                                <div className='nameofUserHere'>
                                    <p>{campaign.user_name}</p>
                                </div>
                                <div className='pledegActiveDisplayData'>
                                    /                                    <p className='text-danger fw-bold text-start' style={{ lineHeight: "0" }}>{campaign.status}</p>
                                    <p className='fw-bold text-start' style={{ fontWeight: "600", lineHeight: "0.3" }}>{campaign.days_remaining} day(s) left</p>
                                    <div className='d-flex'>
                                        <Image src={Coin} className='' style={{ width: "auto", height: "fit-content" }} />
                                        <p className='fw-bold text-start' style={{ fontWeight: "600" }}>Ksh.{campaign.pledge_amount}</p>
                                    </div>
                                </div>
                            </Card>

                        </Link>


                    ))}
                </Slider> */}
            </div>

            {/* mobile view */}
            <div className='mobileActivePledges'>

                <div className='d-flex '>
                    <p onClick={goBack}>
                        <i className='bi bi-chevron-left' style={{ fontSize: "2rem", fontWeight: "800", marginLeft: "1rem" }}></i>
                    </p>
                    <h2 className='text-dark text-center' style={{ alignContent: "center", marginLeft: "3rem", fontSize: "1.2rem", fontWeight: "5000" }}>Active Pledges</h2>
                </div>

                <Form className="searchBarMobile">
                    <FormControl type="text" placeholder="Search By Name" onChange={handleSearch} />
                </Form>
                <p className='mt-2' style={{ fontSize: "12px", color: "grey", textAlign: "center" }}>Tap to Select a Pledge to Redeem it</p>



                <div className='sliderForCampaignMobile'>
                    {filteredData.map((campaign, index) => (
                        <Link to={`/pledge/${campaign.payment_code}/`} className='text-decoration-none text-dark '>
                            <Card key={index} className='activePledgesCards'>
                                <div className='profileUserImage'>

                                    <div style={{ border: "2px solid white", borderRadius: "50%" }}>
                                        {campaign.profile ?
                                            <Image src={campaign.profile_image} />
                                            :
                                            <Image src={campaign.user_image} />
                                        }

                                    </div>

                                </div>
                                <Image className='topImageCampaign' src={campaign.campaign_image} />
                                <div className='nameofUserHere'>
                                    <div className='topSpan'>
                                        {campaign.pledge_type === 'monetary' ?
                                            <span style={{ fontSize: "10px", textTransform: "capitalize", backgroundColor: "#2ecc71", color: "white", padding: "0.2rem", borderRadius: "2px", marginRight: "4px" }}>{campaign.pledge_type}</span>

                                            :
                                            <span style={{ fontSize: "10px", textTransform: "capitalize", backgroundColor: "#3498db", color: "white", padding: "0.2rem", borderRadius: "2px", marginRight: "4px" }}>{campaign.pledge_type}</span>

                                        }

                                    </div>
                                    {campaign.profile ?
                                        <p>{campaign.profile_name}</p>
                                        :
                                        <p>Myself Payment</p>
                                    }
                                    {campaign.status === 'unfulfilled' ?
                                        <>

                                            <p className=' text-start' style={{ lineHeight: "0", marginTop: "-1rem", textTransform: "capitalize", backgroundColor: "#e74c3c", color: "white", padding: "0.6rem", borderRadius: "4px", fontSize: "12px", width: "fit-content" }}>{campaign.status}</p>
                                            <br></br>
                                        </>
                                        : campaign.status === 'partial' ?
                                            <>
                                                <p className=' text-start' style={{ lineHeight: "0", marginTop: "-1rem", textTransform: "capitalize", backgroundColor: "#f39c12", color: "white", padding: "0.6rem", borderRadius: "4px", fontSize: "12px", width: "fit-content" }}>{campaign.status}</p>
                                                <br></br>
                                            </>
                                            :
                                            <>
                                                <p className=' text-start' style={{ lineHeight: "0", marginTop: "-1rem", textTransform: "capitalize", backgroundColor: "#2ecc71", color: "white", padding: "0.6rem", borderRadius: "4px", fontSize: "12px", width: "fit-content" }}>{campaign.status}</p>
                                                <br></br>
                                            </>
                                    }
                                </div>
                                <div className='pledegActiveDisplayData'>

                                    <p className='text-start' style={{ lineHeight: "0", fontSize: "0.7rem", marginBottom: "0.6rem" }}>
                                        <i className='bi bi-calendar-week text-danger me-2'></i>
                                        {campaign.remaining_months} month(s) to go</p>


                                    {/* get the pledge type if the amount is nan then display the description   */}
                                    {campaign.pledge_type === 'monetary' ?
                                        <>
                                            {campaign.currency !== 'KSH' ?
                                                <div className='d-flex'>
                                                    <i className='bi bi-cash-stack text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                    <p className='text-start fw-bold' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.dollar_amount).toLocaleString('en-US')}</p>
                                                </div>
                                                :
                                                <div className='d-flex'>
                                                    <i className='bi bi-cash-stack text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                    <p className='text-start fw-bold' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.pledge_amount).toLocaleString('en-US')}</p>


                                                </div>}



                                            {campaign.contributed_amount === 0 ? <p style={{ height: "1vh" }}></p> : (
                                                <div className='d-flex' style={{ width: "2vw" }}>
                                                    {campaign.status === 'partial' && (
                                                        <div className='d-flex'>
                                                            <Image className='me-2' style={{ marginTop: "-0.2rem", width: "2vw", height: "75%", marginLeft: "0px" }} src={OrangeHeart} />
                                                            <p className='b'></p>
                                                            <p className='text-start fw-bold' style={{ fontSize: "0.7rem", marginBottom: "0.2rem", color: campaign.status === 'unfulfilled' ? '#e74c3c' : campaign.status === 'partial' ? '#f39c12' : '#2ecc71' }}>
                                                                {campaign.currency}.{parseInt(campaign.contributed_amount).toLocaleString('en-US')}
                                                            </p>
                                                        </div>
                                                    )}

                                                </div>
                                            )
                                            }

                                        </>
                                        : campaign.pledge_type === 'in-kind' ?
                                            <>{campaign.estimated_amount &&
                                                <div className='d-flex'>
                                                    <i className='bi bi-cash-stack text-danger me-2' style={{ fontSize: "11px" }}></i>
                                                    <p className='text-start' style={{ fontSize: "0.7rem", fontWeight: "500", marginBottom: "0.2rem" }}>{campaign.currency}. {parseInt(campaign.estimated_amount).toLocaleString('en-US')}</p>
                                                </div>
                                            }
                                                {campaign.in_kind_name &&
                                                    <div className='d-flex'>
                                                        {/* <Image src={Comment} style={{ width: "5%" }} /> */}
                                                        <i className='bi bi-chat-left-text text-danger me-2' style={{ fontSize: "11px" }}></i>

                                                        <p className='text-start truncate-text-description' style={{ fontSize: "0.7rem", marginBottom: "0.2rem" }}>{campaign.in_kind_name}</p>
                                                    </div>
                                                }


                                            </>


                                            :
                                            <></>}




                                </div>
                                <div className='d-flex justify-content-end' style={{ position: "absolute", bottom: "4px", right: "5px" }}>
                                    {campaign.status === 'partial' ?
                                        <span className='btn btn-danger text-white btn-sm' style={{ display: "inline", padding: "5px", fontSize: "12px" }}>
                                            <Link to={`/donation/${campaign.payment_code}`} className='text-white text-decoration-none'>Redeem</Link>
                                        </span>
                                        : campaign.status === 'fulfilled' ?
                                            <span className='btn btn-dark text-white btn-sm' onClick={handleEditPledge} style={{ display: "inline", padding: "5px", fontSize: "12px" }}>
                                                <Link to={`/pledge/${campaign.payment_code}`} className='text-white text-decoration-none'>Edit</Link>

                                            </span>
                                            :
                                            <span className='btn btn-danger text-white btn-sm' style={{ display: "inline", padding: "5px", fontSize: "12px" }}>
                                                <Link to={`/donation/${campaign.payment_code}`} className='text-white text-decoration-none'>Redeem</Link>

                                            </span>

                                    }
                                </div>
                            </Card>

                        </Link>


                    ))}
                </div>

                <div className='d-flex justify-content-between mt-3 bottomButtonsDisplay'>
                    <Link to={'/help-desk'} className='text-decoration-none text-white'>
                        <Button className='btn d-flex btn-danger text-white btn-sm align-items-center' style={{ width: "auto", borderRadius: "20px", fontSize: "17px", padding: "5px" }}>
                            <Image src={LiveChat} style={{ width: "9vw" }} />
                            <span className='fw-bolder' style={{ marginInline: "3px", }}> Help Desk</span>
                        </Button>
                    </Link>
                    {/* <Image src={PlusCircle} className='plusCircleButton' onClick={() => makePledgeModal(true)} /> */}
                    {/* <Image src={PlusCircle} className='plusCircleButton'  /> */}
{/* 
                    {pledgeModal &&
                        <MakePledge onClose={() => makePledgeModal(false)} />

                    } */}

                </div>
            </div>

        </>

    );
}

export default MyPledges;
