import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Card, Container, Row, Col, Image, Modal, Form, FormSelect } from 'react-bootstrap'
import '../../assets/scss/NewHomePage.scss';
import PieChartDashboard from '../components/PieChartDashboard';
import Citam from '../../assets/img/citam_default.png'
import Coin from '../../assets/img/coin-stack.png'
import MyPledges from '../components/MyPledges';
import Calendar from '../../assets/img/calendar.png'
import Whatsapp from '../../assets/img/whatsapp.png'
import Facebook from '../../assets/img/facebook.png'
import Twitter from '../../assets/img/twitter.png'
import Instagram from '../../assets/img/instagram.png'
import Paperclip from '../../assets/img/paperclip.png'
import Mail from '../../assets/img/gmail.png'
import DatePicker from "react-datepicker";
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import { DayPicker } from 'react-day-picker';
import PaymentCalendar from '../components/PaymentCalendar';
import RedeemPledge from '../components/MakePledge';
import { Link } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg } from '../components/cropUtils'
import Cropper from 'react-easy-crop';
import Mpesa from '../../assets/img/mpesa.jpeg'
import Cheque from '../../assets/img/cheque.png'
import Credit from '../../assets/img/credit-card.png'
import Cash from '../../assets/img/money.png'
import PayPal from '../../assets/img/paypal.png'
import Bank from '../../assets/img/bank.png'
import PlaceHolder from '../../assets/img/citam_default.png'
import loaderImage from '../../assets/img/citam_default.png'
import PhoneNumberValidation from '../components/PhoneNumberValidation';

const NewHomePage = ({ title, category, contributions, total_contribution, percentage_collected_against_pledges, days_remaining, pledge_amount, raisedAmount, description, goalAmount, image, ends_on, pledgeRaised, pledgeGoal }) => {
  // const percentagePledge = 0.2 * 100;
  const percentagePledge = (raisedAmount / goalAmount) * 100;
  // const percentagePledgeTotal = 0.08 * 100;
  const percentagePledgeTotal = (pledge_amount / goalAmount) * 100;
  const percentagePledgeRaisedTotal = (raisedAmount / pledge_amount) * 100;

  let percentageCampaign;
  if (pledgeGoal === 0) {
    percentageCampaign = 0;
  }
  else {
    percentageCampaign = (pledgeRaised / pledgeGoal) * 100;

  }
  const [selectedDate, setSelectedDate] = useState(null);

  const handleMyPledges = () => {
    const myPledgesElement = document.getElementById("MyPledges");
    myPledgesElement.scrollIntoView({ behavior: "smooth" });
  }

  const [isLoading, setIsLoading] = useState(true);

  // get the active pledges
  // get the months needed
  const [monthsToFutureDate, setMonthsToFutureDate] = useState(null);

  useEffect(() => {
    const currentDate = new Date();
    const futureDate = new Date(ends_on);
    const differenceInMonths = (futureDate.getFullYear() - currentDate.getFullYear()) * 12 + (futureDate.getMonth() - currentDate.getMonth());
    setMonthsToFutureDate(differenceInMonths);
  });


  const formatDate = (ends_on) => {
    const date = new Date(ends_on);
    const day = date.toLocaleDateString('en-GB', { day: 'numeric' });
    const month = date.toLocaleDateString('en-GB', { month: 'long' });
    const year = date.toLocaleDateString('en-GB', { year: 'numeric' });

    return { day, month, year };
  };
  const formattedDate = formatDate(ends_on);

  // countdown data
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const targetDate = new Date(ends_on).getTime();
      const now = new Date().getTime();
      const timeRemaining = targetDate - now;

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [ends_on]);


  // make pledge data
  const [showPledge, setShowPledge] = useState(false)

  const showPledgeOpen = () => {
    setShowAccounts(false)
    setShowPledge(true)
  }
  const showPledgeClose = () => {
    setShowPledge(false)
    setSelectedDate(null)
  }

  const [showAccounts, setShowAccounts] = useState(false);

  const handleAccountClose = () => setShowAccounts(false);
  const handleAccountShow = () => setShowAccounts(true);

  const [showAddAccounts, setShowAddAccounts] = useState(false);

  const handleAddAccountClose = () => {
    setShowAddAccounts(false)
    setShowAccounts(true)
    setAccountType('other')
  }

  const handleAddAccountShow = () => {
    setShowAddAccounts(true)
    setShowAccounts(false)
  }

  const [pledgeType, setPledgeType] = useState('monetary')

  const handleChangePledgeType = (type) => {

    setPledgeType(type)
  }

  const [modeType, setModeType] = useState('one')

  const handleChangeModeType = (type) => {
    if (type === 'one') {
      makePledgeFormData({
        ...pledgeFormData,
        installment: null,
        payment_frequency: null,
      });
      setSelectedDate(null)

    }

    setModeType(type)
  }

  const [AccountType, setAccountType] = useState('mine')

  const handleChangeAccountType = (type) => {
    setAccountType(type)
  }


  const [AccountTypeAdd, setAccountTypeAdd] = useState(null)

  const handleChangeAccountTypeAdd = (type) => {
    setAccountTypeAdd(type)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [copied, setCopied] = useState(false);

  const websiteUrl = "https://tig.citam.org/#/shared-campaign";
  const appUrl = "https://play.google.com/store/apps/details?id=com.usalamatechnology.citam_fundraise_app";




  const handleCopyLink = () => {
    const textToCopy = `${websiteUrl}`;
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopied(true);
    toast.success("Linked is copied");
  };

  const campaign_target = goalAmount.toLocaleString('en-US')



  const messageLink = `${title}\n${description}\nCampaign Target: KSH.${campaign_target}.\nTo View This Campaign Click The Link below.\n🔗${websiteUrl}`;

  const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
    messageLink
  )}`;

  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    messageLink
  )}`;

  const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    messageLink
  )}`;

  const instagramLink = "https://www.instagram.com/direct/inbox/";

  function shareFacebook() {
    let url = encodeURIComponent(messageLink);
    let title = "title";
    window.open(
      `http://www.facebook.com/sharer.php?u=${url}`,
      "pop up name",
      "width=600, height=600"
    );
  }

  const handlePlatform = (platform) => {
    var Platform = platform;

    if (platform === "whatsapp") {
      window.open(whatsappLink, "_blank");
    } else if (platform === "facebook") {
      // Handle Facebook logic here
      // ...
    } else if (platform === "gmail") {
      window.open(EmailLink);
    } else if (platform === "x") {
      window.open(twitterLink, "_blank");
    } else if (platform === "instagram") {
      window.open(instagramLink, "_blank");
    } else if (platform === "copy_link") {
      handleCopyLink();
    }

  };


  function getCurrentDate() {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month/day is a single digit
    month = (month < 10 ? '0' : '') + month;
    day = (day < 10 ? '0' : '') + day;

    return `${today.getFullYear()}-${month}-${day}`;
  }

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };


  // get the data 
  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token to get the user id
  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;
  const [userProfile, setUserProfile] = useState([])
  const [specialProfiles, setSpecialProfiles] = useState([]);

  const [pledgeCount, setPledgeCount] = useState('')
  const [churchUser, setChurchUser] = useState({});
  const [monetaryCount, setMonetaryCount] = useState(0)
  const [monetaryCountPledge, setMonetaryCountPledge] = useState([])
  const [fullName, setFullName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [UserImage, setUseeImage] = useState('');
  const [UserType, setUserType] = useState('');
  const [pledgeData, setPledgeData] = useState({})
  const [userUniqueIdentifier, setUserUniqueIdentifier] = useState('');
  const [userChurch, setUserChurch] = useState({});
  const [pledgeDataChurch, setPledgeDataChurch] = useState({});

  useEffect(() => {
    setIsLoading(true)
    // get the profiles for the current user
    serverUrl.get(`/user/profile/?user=${userId}`, config)
      .then((res) => {
        setUserProfile(res.data.results)
        setIsLoading(false)

      })
      .catch((error) => {
        console.log(error)
      })
    setIsLoading(true)
    //check if user has a church
    serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
      .then((res) => {
        setUserChurch(res.data.results[0])
        setIsLoading(false)
        serverUrl.get(`/churches/get-all-analysis?church=${res.data.results[0].church}`, config)
          .then((res) => {
            setPledgeDataChurch(res.data[0]);
          })
          .catch((error) => {
            console.log(error);
          });

      })
      .catch((error) => {
        console.log(error)
      })

    serverUrl
      .get(`/user/user-details/?id=${userId}`, config)
      .then((res) => {
        // get the full name of the user
        setFullName(res.data.results[0].full_name);
        setUseeImage(res.data.results[0].profile_picture);
        setIdNumber(res.data.results[0].id_number)
        setUserType(res.data.results[0].account_type)
        setIsLoading(false)

      })
      .catch((error) => {
        console.log(error);
      });

    serverUrl.get(`/churches/churchusers/?user_id=${userId}`, config)
      .then((res) => {
        serverUrl.get(`/pledge/active-pledges?user_id=${userId}`, config)
          .then((res) => {
            setPledgeCount(res.data.results.length);
            setPledgeData(res.data.results[0])

          })
          .catch((error) => {
            console.log(error);
          });
        setChurchUser(res.data.results[0]);


      })
      .catch((error) => {
        console.log(error);
      });


    // get the data for the pledges


  }, [userId])

  const churchPledge = (pledgeDataChurch.total_collected / pledgeDataChurch.total_pledges) * 100
  const churchRedeemption = (pledgeDataChurch.total_collected / pledgeDataChurch.target_amount) * 100

  const [selectedUserProfile, setSelectedUserProfile] = useState('')
  const handleUserProfileChange = (event) => {
    setSelectedUserProfile(event.target.value);
    serverUrl.get(`/pledge/get-monetary-pledges/?user_id=${userId}&profile_id=${event.target.value}`, config)
      .then((res) => {
        setMonetaryCount(res.data.count)
        setMonetaryCountPledge(res.data.pledge[0])
      })
      .catch((error) => {
        console.log(error)
      })
  };

  // add account functionality
  const [selectedUserProfileRelation, setSelectedUserProfileRelation] = useState('')
  const handleUserProfileRelationChange = (event) => {
    setSelectedUserProfileRelation(event.target.value);
  };

  const handleUserProfileClick = () => {
    const fileInput = document.getElementById('imageUpload');
    fileInput.click();
  };

  const [images, setImages] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const handleZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const handleChangeImage = (e) => {

    const file = e.target.files[0];

    setImages(file);
  };
  const [cropImage, setCropImage] = useState([]);
  const handleCropImage = async (e) => {

    try {
      const croppedImage = await getCroppedImg(images, croppedAreaPixels);
      setCropImage(croppedImage);
      console.log(croppedImage)
      setImages(null)
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  // const handleImageLoaded = (image) => {
  //   console.log('Image loaded:', image);
  //   // Automatically crop the image into a square
  //   const width = Math.min(image.width, image.height);
  //   const height = width;
  //   const crop = {
  //     unit: 'px',
  //     width,
  //     height,
  //     x: (image.width - width) / 2,
  //     y: (image.height - height) / 2
  //   };
  //   handleCropChange(crop);
  // };

  // const handleCropChange = (crop) => {
  //   if (crop.width && crop.height) {
  //     // Only set cropped image when crop is complete
  //     setCroppedImage(crop);
  //   }
  // };

  // const handleSaveImage = () => {
  //   // Process and save the cropped image
  //   // For demonstration, just set croppedImage to the cropped version of the original image
  //   setCroppedImage(image);
  // };

  // uploading


  const NoPLedge = () => {
    toast.error('You have no pledge at the moment kindly add one')
  }

  const [userProfileName, setUserProfileName] = useState('')

  useEffect(() => {
    // get the monetary status
    if (AccountType === 'other') {
      serverUrl.get(`/pledge/get-monetary-pledges/?user_id=${userId}&profile_id=${selectedUserProfile}`, config)
        .then((res) => {
          setMonetaryCount(res.data.count)
          setMonetaryCountPledge(res.data.pledge[0])
        })
        .catch((error) => {
          console.log(error)
        })
    }
    else {
      serverUrl.get(`/pledge/get-monetary-pledges/?user_id=${userId}`, config)
        .then((res) => {
          setMonetaryCount(res.data.count)
          setMonetaryCountPledge(res.data.pledge[0])
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [AccountType])

  useEffect(() => {
    userProfile.map((profile) => {
      serverUrl.get(`/pledge/get-monetary-pledges/?user_id=${userId}&profile_id=${profile.id}`, config)
        .then((res) => {
          if (res.data.count > 0) {
            setSpecialProfiles((prevSpecialProfiles) => [...prevSpecialProfiles, profile.id]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, [userProfile]);

  function handleAddNewProfileAccount(e) {
    e.preventDefault();
    // check if user has entered phone number
    const phoneNumber = localStorage.getItem('number')
    const country = localStorage.getItem('country')
    const countryCode = localStorage.getItem('countryCode')

    if (phoneNumber) {
      const dataPhone = {
        user: userId,
        phone_number: phoneNumber,
        country_code: countryCode,
        country_name: country,
      }
      // post the data and get the id
      serverUrl.post('/user/user-linked-phone-numbers/', dataPhone, config)
        .then((res) => {
          localStorage.removeItem('number')
          localStorage.removeItem('country')
          localStorage.removeItem('countryCode')
          // post the data now
          const formData = new FormData();
          // Append user, relation, and profile_name fields
          formData.append('user', userId);
          formData.append('relation', selectedUserProfileRelation);
          formData.append('profile_name', userProfileName);
          formData.append('phone_number', res.data.id);


          if (cropImage instanceof Blob || cropImage instanceof File) {
            // Get the current date and time
            const currentDate = new Date();
            // Construct a unique file name using the current date and time
            const fileName = `cropped_image_${currentDate.getTime()}.png`;

            // Append the cropped image to FormData with the constructed file name
            formData.append('image', cropImage, fileName);
          }


          serverUrl.post(`/user/profile/`, formData, config)
            .then((res) => {
              toast.success('Succesfully added the Account')
              setImages(null);
              setSelectedUserProfileRelation(null);
              setUserProfileName(null);
              setShowAddAccounts(false)
              handleChangeAccountType('other')
              setShowAccounts(true)

              // get the profiles for the current user
              serverUrl.get(`/user/profile/?user=${userId}`, config)
                .then((res) => {
                  setUserProfile(res.data.results)
                })
                .catch((error) => {
                  console.log(error)
                })

            })
            .catch((error) => {
              console.log(error)
            })


        })
        .catch((error) => {
          console.log(error)
        })

    }

    else {
      // Create a new FormData object
      const formData = new FormData();
      // Append user, relation, and profile_name fields
      formData.append('user', userId);
      formData.append('relation', selectedUserProfileRelation);
      formData.append('profile_name', userProfileName);
      if (cropImage instanceof Blob || cropImage instanceof File) {
        // Get the current date and time
        const currentDate = new Date();
        // Construct a unique file name using the current date and time
        const fileName = `cropped_image_${currentDate.getTime()}.png`;

        // Append the cropped image to FormData with the constructed file name
        formData.append('image', cropImage, fileName);
      }

      serverUrl.post(`/user/profile/`, formData, config)
        .then((res) => {
          toast.success('Succesfully added the Account')
          setImages(null);
          setSelectedUserProfileRelation(null);
          setUserProfileName(null);
          setShowAddAccounts(false)
          handleChangeAccountType('other')
          setShowAccounts(true)

          // get the profiles for the current user
          serverUrl.get(`/user/profile/?user=${userId}`, config)
            .then((res) => {
              setUserProfile(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })

        })
        .catch((error) => {
          console.log(error)
        })
    }


  }


  // make pledge 
  const [pledgeFormData, makePledgeFormData] = useState({
    pledgeAmount: "",
    currency: "KSH",
    payment_frequency: "",
    installment: "",
    estimated_amount: ""
  })

  const [chequeData, setChequeData] = useState({
    chequeNumber: "",
    currency: "KSH",
    transaction_date: "",
    amount: "",
  })
  const [isInstallmentEditable, setIsInstallmentEditable] = useState(true);

  const handlePledgeInput = (e) => {
    const { name, value } = e.target;
    // Check if the input is for installment

    // Check if the input is for installment
    if (name === 'installment') {
      const parsedInstallment = parseInt(value);
      const parsedPledgeAmount = parseInt(pledgeFormData.pledgeAmount);

      // Check if the installment is greater than the pledge amount
      if (parsedInstallment > parsedPledgeAmount) {
        toast.error("Installments amount can't be greater than the pledged amount.");
        return;
      }

      // Disable installment input if installment is greater than pledge amount
      setIsInstallmentEditable(value === '' || parsedInstallment <= parsedPledgeAmount);
    }
    makePledgeFormData({
      ...pledgeFormData,
      [name]: value,
    });
    setChequeData({
      ...chequeData,
      [name]: value,
    });
  };


  // get the currency data immediately 
  const [currencyRate, setCurrencyRate] = useState('')
  const valueKSH = currencyRate * pledgeFormData.pledgeAmount

  useEffect(() => {
    if (pledgeFormData.currency !== 'KSH') {
      serverUrl.get(`/pledge/get-currency?amount=1&currency=${pledgeFormData.currency}`, config)
        .then((res) => {
          setCurrencyRate(res.data.converted_amount)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [pledgeFormData.currency])
  const [smsChecked, setSmsChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);

  const handleSmsChange = (e) => {
    setSmsChecked(e.target.checked);
  };

  const handleEmailChange = (e) => {
    setEmailChecked(e.target.checked);
  };
  const [phoneNumber, setPhoneNumber] = useState('');


  function handleMakePlede(e) {
    e.preventDefault()


    var dollar_amount_new;
    var churchData;
    var endDate;
    let pledgeAmount;


    endDate = localStorage.getItem('endDate')

    if (pledgeFormData.estimated_amount) {
      dollar_amount_new = parseInt(pledgeFormData.estimated_amount);
    }
    else if (pledgeFormData.pledgeAmount) {
      dollar_amount_new = parseInt(pledgeFormData.pledgeAmount);
    }

    if (pledgeFormData.currency !== 'KSH') {
      pledgeAmount = valueKSH
    }
    else {
      pledgeAmount = dollar_amount_new
    }
    if (churchUser) {
      churchData = churchUser.church
    } else {
      null
    }

    const PledgeData = {
      amount: pledgeAmount,
      original_amount: pledgeAmount,
      currency: pledgeFormData.currency,
      pledge_type: pledgeType,
      in_kind_name: pledgeFormData.in_kind_name,
      payment_frequency: pledgeFormData.payment_frequency,
      installment_start_date: selectedDate,
      installment: pledgeFormData.installment,
      status: "unfulfilled",
      user: userId,
      giving_campaign: 1,
      church: churchData,
      profile: selectedUserProfile,
      estimated_amount: pledgeFormData.estimated_amount,
      is_sms: smsChecked,
      is_email: emailChecked,

    }
    if (pledgeFormData.currency !== 'KSH') {
      PledgeData.dollar_amount = pledgeFormData.pledgeAmount

    }
    if (formattedEndDateData && formattedEndDateData !== "01/01/1970") {
      const originalDateStr = formattedEndDateData;
      const parts = originalDateStr.split('/');
      const formattedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
      PledgeData.installment_end_date = formattedDate

    }
    // create the pledge 
    serverUrl.post('/pledge/', PledgeData, config)
      .then((res) => {
        toast.success('Succesfully Added the pledge')
        makePledgeFormData({
          pledgeAmount: "",
          currency: "KSH",
          payment_frequency: "",
          installment: "",
          estimated_amount: "",
        });
        setAccountType('mine')
        setSelectedUserProfile('')
        setShowPledge(false)
        setPledgeType('monetary')
        setSelectedDate(null)
        setConfrimSavePledge(false)
        // get the pledge
        serverUrl.get(`/pledge/active-pledges?user_id=${userId}`, config)
          .then((res) => {
            setPledgeCount(res.data.results.length)
          })
          .catch((error) => {
            console.log(error)
          })
        // post the id number to the backend

        if (!idNumber) {
          const dataUser = {
            id_number: userUniqueIdentifier
          }
          serverUrl.patch(`/user/edit-user/${userId}`, dataUser, config)
            .then((res) => {
              serverUrl
                .get(`/user/user-details/?id=${userId}`, config)
                .then((res) => {
                  // get the full name of the user
                  setFullName(res.data.results[0].full_name);
                  setUseeImage(res.data.results[0].profile_picture);
                  setIdNumber(res.data.results[0].id_number)
                  setUserType(res.data.results[0].account_type)
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error)
            })

        }

      })
      .catch((error) => {
        console.log(error)
        toast.error('An error occured')

      })
  }

  const [showModalSchedule, setShowModalSchedule] = useState(false)

  const showScheduleOpen = () => {


    const campaignEndDate = new Date(ends_on);

    if (endDate > campaignEndDate) {
      setAddErrorMessage(true)
      return;
    }
    else {
      setShowModalSchedule(true)
      setShowPledge(false)
    }
  }
  const showScheduleClose = () => {
    setShowPledge(true)
    setShowModalSchedule(false)
  }

  const [confirmSavePledge, setConfrimSavePledge] = useState(false)

  let numberOfInstallments;

  function calculateEndDate() {
    // Convert startDate to Date object
    const startDateObj = new Date(selectedDate);

    // Calculate the number of installments required
    numberOfInstallments = Math.ceil(pledgeFormData.pledgeAmount / pledgeFormData.installment);
    const endDate = new Date(startDateObj);

    // Adjust frequency for months and years
    if (pledgeFormData.payment_frequency === 'monthly') {
      endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments, endDate.getDate());
    } else if (pledgeFormData.payment_frequency === '3months') {
      endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments * 3, endDate.getDate());
    } else if (pledgeFormData.payment_frequency === '6months') {
      endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments * 6, endDate.getDate());
    } else if (pledgeFormData.payment_frequency === 'yearly') {
      endDate.setFullYear(endDate.getFullYear() + numberOfInstallments, endDate.getMonth(), endDate.getDate());
    } else if (pledgeFormData.payment_frequency === 'weekly') {
      endDate.setDate(endDate.getDate() + numberOfInstallments * 7); // Adjust based on number of weeks in a year
    } else if (pledgeFormData.payment_frequency === '2weeks') {
      endDate.setDate(endDate.getDate() + numberOfInstallments * 14); // Adjust based on number of bi-weekly periods in a year
    } else {
      // Handle other frequencies
      numberOfInstallments *= 1; // Adjust accordingly
    }



    return endDate;
  }
  const endDate = calculateEndDate();

  const [addErrorMessage, setAddErrorMessage] = useState(false)
  // Assuming endDate is a Date object
  const formattedEndDate = `${endDate.toLocaleString('default', { month: 'long' })} ${endDate.getDate()} ${endDate.getFullYear()}`;
  const formattedEndDateData = new Date(endDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });


  let formattedStartDate = '';

  if (selectedDate !== null && selectedDate !== undefined) {
    const dateObj = new Date(selectedDate);
    formattedStartDate = `${dateObj.toLocaleString('default', { month: 'long' })} ${dateObj.getDate()} ${dateObj.getFullYear()}`;
  }

  const ends_on_date = new Date(ends_on);
  const formattedEndsOn = `${ends_on_date.toLocaleString('default', { month: 'long' })} ${ends_on_date.getDate()} ${ends_on_date.getFullYear()}`;




  const showSavePledge = () => {

    // Calculate the end date
    if (pledgeType === 'monetary' && !pledgeFormData.pledgeAmount) {
      toast.error('Kindly enter the amount for the pledge')
      return
    }
    if (modeType === 'regular' && !pledgeFormData.payment_frequency) {
      toast.error('Kindly ensure the pledge frequency is added')
      return
    }
    if (modeType === 'regular' && pledgeFormData.payment_frequency && !selectedDate) {
      toast.error('Kindly ensure the start installment date for the pledge is set')
      return
    }
    if (modeType === 'regular' && !pledgeFormData.installment && pledgeFormData.payment_frequency) {
      toast.error('Kindly ensure the installment amount for the pledge is added.');
      return;
    }


    const campaignEndDate = new Date(ends_on);

    // Check if the end date exceeds the campaign end date
    if (endDate > campaignEndDate) {
      // toast.error('The end date exceeds the campaign end date. Pledge cannot be created.');
      setAddErrorMessage(true)
      return;
    }
    else

      if (!pledgeFormData.in_kind_name && !pledgeFormData.estimated_amount && pledgeType === 'in-kind') {
        toast.error('Please fill any of the fields.');
        return;
      }
      else {
        setConfrimSavePledge(true)
        setShowPledge(false)
      }
  }
  const closeSavePledge = () => {
    setConfrimSavePledge(false)

  }
  const closeSavePledgeReturn = () => {
    setConfrimSavePledge(false)
    setShowPledge(true)

  }
  const [showRedeem, setShowRedeem] = useState(false)
  const handleOpenRedeem = () => {
    setShowRedeem(true)
  }
  const StatusModal = localStorage.getItem('Pledge')
  let showStatus;
  if (StatusModal === null) {
    showStatus = true

  }
  if (!StatusModal) {
    showStatus = true
  }

  const formatAmount = (amount) => {
    if (amount >= 1000000000) {
      return (amount / 1000000000).toFixed(2) + 'B';
    } else if (amount >= 1000000) {
      return (amount / 1000000).toFixed(2) + 'M';
    } else if (amount >= 1000) {
      return (amount / 1000).toFixed(2) + 'K';
    } else {
      return amount;
    }
  };





  // const [campaignStatement, setCampaignStatements] = useState({})

  // useEffect(() => {
  //   serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=0`, config)
  //     .then((res) => {
  //       setPledgeStatements(res.data.results[0].pledge_statement)
  //       setContributorStatements(res.data.results[0].contributor)
  //       setCampaignStatements(res.data.results[0].campaign_data[0])
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })

  //   // get the pledge data 
  //   serverUrl.get(`/pledge/active-pledges/?user_id=${userId}`, config)
  //     .then((res) => {
  //       // console.log(res.data)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })

  //   serverUrl
  //     .get(`/user/user-details/?id=${userId}`, config)
  //     .then((res) => {
  //       // get the full name of the user
  //       setFullName(res.data.results[0].full_name);
  //       setPhoneNumber(res.data.results[0].phone_number);

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //   // get the church details of user
  //   serverUrl.get(`/churches/church-users-logo-name/?user_id=${userId}`)
  //     .then((res) => {
  //       setUserChurch(res.data.results[0])
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }, [])

  const [modalShow, setModalShow] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const handleOpenRedeemModal = () => {
    setModalShow(true);

  }
  const handleShowModal = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setSelectedPaymentMethod('');
  };
  const [donationAmount, setDonationAmount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [bankTransferOption, setBankTransferOption] = useState('PESALINK');
  const handleButtonClick = (index, amount) => {
    setActiveIndex(index);
    setDonationAmount(amount)
  };
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleBankTransferOptionChange = (option) => {
    setBankTransferOption(option);
  };

  const handleBankTransferSelect = (option) => {
    handleBankTransferOptionChange(option);
    setSelectedPaymentMethod(option);
  };


  // get post the data for the redeemption
  function handleRedeemAdd(e) {
    const description = `id-${campaignStatement.campaign_id}-${pledgeData.payment_code}`
    e.preventDefault()

    if (selectedPaymentMethod === 'MPESA') {
      const data = {
        amount: donationAmount,
        phone_number: phoneNumber,
        reference: id,
        description: description,
        source: "web",
        updated_by: userId,
      }

      if (userChurch) {
        data.church = userChurch.id;
      }
      serverUrl.post(`giving/checkout/`, data, config)
        .then((res) => {
          setSelectedPaymentMethod(null);
          toast.success('An mpesa prompt has been sent to your phone enter pin to make payment');
          // Reload the page after 8 seconds
          setChequeData({
            chequeNumber: "",
            currency: "KSH",
            transaction_date: "",
            amount: "",
          })
          setTimeout(() => {
            window.location.reload();
          }, 8000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (selectedPaymentMethod === 'CHEQUE') {
      const formattedDate = chequeData.transaction_date.replace(/-/g, '');
      const splitName = fullName.split(' ')
      const firstName = splitName[0];
      const lastName = splitName[splitName.length - 1];
      const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;

      const formData = new FormData();
      formData.append("payment_code", pledgeData.payment_code);
      formData.append("cheque_number", chequeData.chequeNumber);
      formData.append("currency", chequeData.currency);
      formData.append("transcation_amount", parseInt(donationAmount));
      formData.append("transaction_date", chequeData.transaction_date);
      formData.append("transcation_time", formattedDate + '000000');
      formData.append("payment_channel", "cheque");
      formData.append("phone_number", phoneNumber);
      formData.append("status", "pending");
      formData.append("updated_by", userId);
      formData.append("source", "web");
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("middle_name", middleName);
      formData.append("bill_ref_number", id);

      // Check if cheque image is present
      if (images) {
        formData.append("cheque_image", images);
      }
      if (userChurch) {
        formData.append("church", userChurch.id);
      }

      serverUrl.post(`/giving/create-ctb-transaction/`, formData)
        .then((res) => {
          toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
          setModalShow(false);
          setChequeData({
            chequeNumber: "",
            currency: "KSH",
            transaction_date: "",
            amount: "",
          })

          serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${pledgeData.payment_code}`, config)
            .then((res) => {
              setPledgeStatements(res.data.results[0].pledge_statement)
              setContributorStatements(res.data.results[0].contributor)
              setCampaignStatements(res.data.results[0].campaign_data[0])
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }

    else if (selectedPaymentMethod === 'BANK TRANSFER') {
      const formattedDate = chequeData.transaction_date.replace(/-/g, '');
      const splitName = fullName.split(' ')
      const firstName = splitName[0];
      const lastName = splitName[splitName.length - 1];
      const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;

      const bankData = {
        payment_code: pledgeData.payment_code,
        bank_payment_channel: bankTransferOption,
        bank_transaction_reference: chequeData.chequeNumber,
        currency: chequeData.currency,
        transcation_amount: donationAmount,
        transaction_date: chequeData.transaction_date,
        transcation_time: formattedDate + '000000',
        payment_channel: "bank",
        phone_number: phoneNumber,
        status: "pending",
        updated_by: userId,
        source: "web",
        first_name: firstName,
        last_name: lastName,
        bill_ref_number: id,

      };

      if (middleName) {
        bankData.middle_name = middleName

      }

      serverUrl.post(`/giving/create-ctb-transaction/`, bankData)
        .then((res) => {
          toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
          setModalShow(false);
          serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${pledgeData.payment_code}`, config)
            .then((res) => {
              setPledgeStatements(res.data.results[0].pledge_statement)
              setContributorStatements(res.data.results[0].contributor)
              setCampaignStatements(res.data.results[0].campaign_data[0])
              setChequeData({
                chequeNumber: "",
                currency: "KSH",
                transaction_date: "",
                amount: "",
              })
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }

  const phoneStyles = { marginBottom: "10px", border: "1px solid #9A1712", borderRadius: "10px" }

  const inputRef = useRef(null);

  let fillColorClass;

  if (percentagePledge < 33) {
    fillColorClass = '#e74c3c';
  } else if (percentagePledge < 67) {
    fillColorClass = '#f39c12';
  } else {
    fillColorClass = '#2ecc71';

  }
  // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={loaderImage} style={{ width: "5%" }} alt="Loading..." />
      </div>
    );
  }


  return (
    <div className='NewHomePage'>
      {/* on the web view */}
      <div className="pledgeDisplay">
        <p className='categoryCampaign'>{category}</p>
        <h1 className=''>{title}</h1>
        <div className="image-container">
          <img src={image} alt="Placeholder" className='TopDisplayImage' />
        </div>

        {/* the below container */}
        <Container>
          <Card className='TopDisplayCard'>
            <Card.Body>

              <p className='w-10 text-white fw-bold' style={{ width: "11%", alignSelf: "center" }}>
                <span style={{ fontSize: "8px" }}>ENDS ON</span><br></br>
                <span style={{ fontSize: "3rem" }}>{formattedDate.day}</span><br></br> {formattedDate.month} {formattedDate.year}
              </p>
              <div className='d-flex  justify-content-between bg-white w-100' style={{ marginBottom: "-1rem", marginRight: "-1rem" }}>
                <div className='p-4' style={{ alignSelf: "center", textAlign: "left" }}>
                  <span className='text-uppercase'>Generosity in action</span><br></br>
                  <span className='fw-bold text-uppercase'>Christian Responsibility and Stewardship</span>
                  <br></br>
                  {monthsToFutureDate === 1 ?
                    <p className='text-uppercase text-white btn btn-sm btn-danger p-2' style={{ fontSize: "13px", fontWeight: "bold", textAlign: "left", borderRadius: "15px" }}>{monthsToFutureDate} month to go</p>

                    : monthsToFutureDate === 0 ?

                      <p className='text-uppercase text-white btn btn-sm btn-danger p-2' style={{ fontSize: "13px", fontWeight: "bold", textAlign: "left", borderRadius: "15px" }}>~ less than one month to go</p>
                      :
                      <p className='text-uppercase text-white btn btn-sm btn-danger p-2' style={{ fontSize: "13px", fontWeight: "bold", textAlign: "left", borderRadius: "15px" }}>{monthsToFutureDate} months to go</p>
                  }
                </div>

                <div className='d-flex' style={{ alignItems: "center" }}>




                  <div className='d-flex justify-content-between ms-4 TopDisplayButtons'>

                    <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-2' style={{ alignSelf: "center" }} onClick={handleAccountShow}>Make Pledge</Button>
                    <Button className='btn btn-sm bg-transparent fw-bold text-danger border-danger p-2 me-2' style={{ alignSelf: "center" }} onClick={handleShow}>Share Campaign</Button>

                    {pledgeCount === 0 ?
                      <Button className='btn btn-sm btn-dark fw-bold text-white p-2 me-4' style={{ alignSelf: "center" }}>My Pledges
                        <span className='topCounterPledge'>{pledgeCount}</span>

                      </Button>

                      :
                      <Button className='btn btn-sm btn-dark fw-bold text-white p-2 me-4' style={{ alignSelf: "center" }} onClick={handleMyPledges}>My Pledges
                        <span className='topCounterPledge'>{pledgeCount}</span>

                      </Button>
                    }


                  </div>

                </div>


              </div>
            </Card.Body>
          </Card>

          <Card className='BottomDisplayCard'>

            <Card.Body>
              <Row>
                <Col md={5} style={{ textAlign: "left" }}>
                  <Card className='campaignDescription'>
                    <h4 className=''>{title}</h4>
                    <p style={{ fontSize: "12px" }}> {description}</p>

                  </Card>


                </Col>

                <Col md={7} className='campaignPieProgress' style={{}}>

                  <div className='d-flex justify-content-between' style={{ borderBottom: "1px solid lightgray" }}>
                    <div>
                      <h6 className='text-start mt-2' >Campaign Target</h6>
                      {goalAmount &&
                        <p className='text-start' style={{ marginBottom: "0" }}><span className='text-danger'>KSH.</span><b style={{ fontSize: "25px", color: "rgb(46, 204, 113)" }}>{goalAmount.toLocaleString('en-US')}</b></p>

                      }
                    </div>
                    <div>
                      <h6 className='mt-2'>Total No of Pledges</h6>

                      {contributions &&
                        <p><b style={{ fontSize: "25px", color: "#8e44ad" }}>{contributions.toLocaleString('en-US')}</b> pledges</p>
                      }
                    </div>



                  </div>

                  <div className='d-flex justify-content-between border-0' >

                    <div className='pledgeProgressPiePledge'>
                      <PieChartDashboard
                        percentage={percentagePledgeTotal}
                        colorPledge={true}

                      />
                      {pledge_amount ?
                        percentagePledgeTotal < 33 ?
                          <p className='ms-2' style={{ fontSize: "12px", fontWeight: "800", marginTop: "-0.4rem", color: "#e74c3c", marginBottom: "0.1rem" }}>Ksh.{formatAmount(pledge_amount)} </p>
                          : percentagePledgeTotal > 33 && percentagePledgeTotal < 67 ?
                            <p className='ms-2' style={{ fontSize: "12px", fontWeight: "82000", marginTop: "-0.4rem", color: "#f39c12", marginBottom: "0.1rem" }}>Ksh. {formatAmount(pledge_amount)}</p>

                            :
                            <p className='ms-2' style={{ fontSize: "12px", fontWeight: "6800", marginTop: "-0.4rem", color: "#2ecc71", marginBottom: "0.1rem" }}>Ksh. {formatAmount(pledge_amount)}</p>
                        :

                        <p className='ms-2' style={{ fontSize: "12px", fontWeight: "800", marginTop: "-0.4rem", color: "#e74c3c", marginBottom: "0.1rem" }}>Ksh. 0</p>
                      }
                      {contributions &&
                        <span style={{ color: "#e67e22", fontSize: "10px" }}>{contributions.toLocaleString('en-US')} pledges</span>

                      }

                      <p style={{ fontSize: "12px" }}>Pledge Progress</p>


                    </div>

                    <div className='divPledgeProgress' >

                    </div>

                    <div className='pledgeProgressPie'>
                      <PieChartDashboard
                        percentage={percentagePledgeRaisedTotal}
                        colorRedemption={true}

                      />
                      {raisedAmount ?
                        percentagePledgeRaisedTotal < 33 ?
                          <p className='ms-2' style={{ fontSize: "12px", fontWeight: "800", marginTop: "-0.4rem", color: "#e74c3c", marginBottom: "0.1rem" }}>Ksh. {formatAmount(raisedAmount)}</p>
                          : percentagePledgeRaisedTotal > 33 && percentagePledgeRaisedTotal < 67 ?
                            <p className='ms-2' style={{ fontSize: "12px", fontWeight: "82000", marginTop: "-0.4rem", color: "#f39c12", marginBottom: "0.1rem" }}>Ksh. {formatAmount(raisedAmount)}</p>

                            :
                            <p className='ms-2' style={{ fontSize: "12px", fontWeight: "6800", marginTop: "-0.4rem", color: "#2ecc71", marginBottom: "0.1rem" }}>Ksh. {formatAmount(raisedAmount)}</p>
                        :

                        <p className='ms-2' style={{ fontSize: "12px", fontWeight: "800", marginTop: "-0.4rem", color: "#e74c3c", marginBottom: "0.1rem" }}>Ksh. 0</p>
                      }
                      {total_contribution &&
                        <span style={{ color: "#8e44ad", fontSize: "10px" }}>{total_contribution.toLocaleString('en-US')} contributions</span>

                      }
                      <p style={{ fontSize: "12px" }}>Redemption Percentage</p>


                    </div>


                    <div className='divPledgeProgress' >

                    </div>


                    <div className='campaignProgressPie'>
                      <PieChartDashboard
                        percentage={percentageCampaign}
                      />
                      {pledgeRaised ?
                        percentageCampaign < 33 ?
                          <p className='ms-2' style={{ fontSize: "12px", fontWeight: "800", marginTop: "-0.4rem", color: "#e74c3c", marginBottom: "0.1rem" }}>Ksh. {formatAmount(pledgeRaised)}</p>
                          : percentageCampaign > 33 && percentageCampaign < 67 ?
                            <p className='ms-2' style={{ fontSize: "12px", fontWeight: "82000", marginTop: "-0.4rem", color: "#f39c12", marginBottom: "0.1rem" }}>Ksh. {formatAmount(pledgeRaised)}</p>

                            :
                            <p className='ms-2' style={{ fontSize: "12px", fontWeight: "6800", marginTop: "-0.4rem", color: "#2ecc71", marginBottom: "0.1rem" }}>Ksh. {formatAmount(pledgeRaised)}</p>


                        :

                        <p className='ms-2' style={{ fontSize: "12px", fontWeight: "800", marginTop: "-0.4rem", color: "#e74c3c", marginBottom: "0.1rem" }}>Ksh. 0</p>
                      }
                      {pledgeCount && (
                        <span style={{ fontSize: "10px", color: "#2980b9" }}>{pledgeCount.toLocaleString('en-US')} pledges</span>

                      )}
                      <p style={{ fontSize: "12px", marginLeft: "15px" }}>Total Pledge Progress</p>

                    </div>



                  </div>

                  <div className='mt-5'>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {pledgeCount === 0 ?

            <></>
            :
            <div id='MyPledges'>
              <MyPledges pledgeCount={pledgeCount} />

            </div>
          }


        </Container>




      </div>

      {/* on the mobile view */}
      <div className='pledeDisplayMobile'>
        <img src={image} alt="Placeholder" className='TopDisplayImage' />
        <p className='topMobileShare' onClick={handleShow}>
          <i style={{ fontSize: "1.5rem" }} className='bi bi-share-fill text-danger'></i>
        </p>

        <div className="campaign-info">
          <div className="campaign-header mb-2">
            <h5>{title}</h5>
            <div className="d-flex align-items-center">
              {Citam ?
                <Image src={Citam} className='profileImage' style={{ height: "8vh", borderRadius: "0", width: "auto" }} />
                :
                <Image src={PlaceHolder} className='profileImage' style={{ height: "8vh", borderRadius: "0", width: "auto" }} />
              }
              <div className="donate-button">CITAM-TIG</div>
            </div>
          </div>

          <Card className="campaign-details">
            <Card.Body>
              <div className='d-flex justify-content-between' style={{ marginTop: "-10px" }}>
                <div className="my-pledge">Target</div>
                <div className="my-pledge"  >Raised</div>


              </div>
              <div className='d-flex justify-content-between'>
                {goalAmount && raisedAmount && (
                  <>
                    <span className="amount fw-bold"> KSH. {formatAmount(goalAmount)}</span>
                  </>
                )}
                {/* <span className="amount text-danger fw-bold"><b>KSH.</b> {raisedAmount}</span> */}
                <span className="amount text-danger fw-bold"><b>KSH.</b> {formatAmount(raisedAmount)}</span>


              </div>

              <div className="progress">
                {/* <div className="progress-fill" style={{ width: `${percentagePledge}%` }} /> */}
                <div className="progress-fill" style={{ width: `${percentagePledgeTotal}%` }} />
              </div>

              <div className='d-flex justify-content-between'>
                {days_remaining === 1 ?
                  <div className="days-left"><i className='bi bi-clock text-danger me-1'></i>{days_remaining} month to go</div>
                  : days_remaining === 0 ?
                    <div className="days-left"><i className='bi bi-clock text-danger me-1'></i>~ less than one month to go</div>

                    :
                    <div className="days-left"><i className='bi bi-clock text-danger me-1'></i>{days_remaining} month(s) to go</div>

                }
                {contributions &&
                  <div className="days-left">
                    <Image src={Coin} className='me-1' style={{ width: "12%" }} />

                    {contributions.toLocaleString('en-US')} pledges

                  </div>
                }

              </div>

            </Card.Body>
          </Card>




          <div className="about mt-3">
            <h5>About Campaign</h5>
            <p> {description}</p>
          </div>

          <div className='d-flex justify-content-between GraphCardsDisplay' style={{ marginBottom: "20px" }}>
            <Card>
              <span className='btn btn-sm text-dark churchButtonDisplay' style={{ background: "yellow" }} >Overall Pledge</span>

              <div className='pledgeProgressPiePledge' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ position: 'relative' }}>
                  <PieChartDashboard
                    percentage={percentagePledgeTotal}
                    colorPledge={true}

                  />
                  {pledge_amount ? (
                    <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>KSH. {formatAmount(pledge_amount)}</span>
                  )
                    :
                    (
                      <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>0</span>

                    )
                  }
                </div>
                <p style={{ fontSize: "12px", marginTop: "-1.5rem", marginBottom: "0.2rem" }}>Pledge Progress</p>
              </div>

            </Card>

            <Card>
              <span className='btn btn-sm text-dark churchButtonDisplay' style={{ background: "yellow" }} >Overall Pledge Redemption</span>

              <div className='pledgeProgressPiePledge' style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ position: 'relative' }}>
                  <PieChartDashboard
                    percentage={percentagePledgeRaisedTotal}
                    colorRedemption={true}
                  />
                  {raisedAmount ? (
                    <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>KSH. {formatAmount(raisedAmount)}</span>
                  )
                    :
                    (
                      <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>0</span>

                    )
                  }
                </div>
                <p style={{ fontSize: "12px", marginTop: "-1.5rem", marginBottom: "0.2rem" }}>Redemption Percentage</p>
              </div>
            </Card>




          </div>
          {userChurch && (

            <div className='d-flex justify-content-between GraphCardsDisplay'>
              <Card>
                <div className='pledgeProgressPiePledge' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <span className='btn btn-sm btn-danger text-white churchButtonDisplay' >{userChurch.church_name}</span>

                  <div style={{ position: 'relative' }}>
                    <PieChartDashboard
                      percentage={churchPledge}
                      colorPledge={true}

                    />
                    {pledgeDataChurch.total_pledges ? (
                      <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>KSH. {formatAmount(pledgeDataChurch.total_pledges)}</span>
                    )
                      :
                      (
                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>0</span>

                      )
                    }
                  </div>
                  <p style={{ fontSize: "12px", marginTop: "-1.5rem", marginBottom: "0.2rem" }}>Assembly Pledge Progress</p>
                </div>

              </Card>

              <Card>
                <div className='pledgeProgressPiePledge' style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <span className='btn btn-sm btn-danger text-white churchButtonDisplay' >{userChurch.church_name}</span>

                  <div style={{ position: 'relative' }}>

                    <PieChartDashboard
                      percentage={churchRedeemption}
                      colorRedemption={true}
                    />
                    {pledgeDataChurch.total_collected ? (
                      <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>KSH. {formatAmount(pledgeDataChurch.total_collected)}</span>
                    )
                      :
                      (
                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: "11px" }}>0</span>

                      )
                    }
                  </div>
                  <p style={{ fontSize: "12px", marginTop: "-1.5rem", marginBottom: "0.2rem" }}>Assembly Redemption Percentage</p>
                </div>
              </Card>




            </div>
          )}


        </div>
        <div className="actions">
          <button className="btn btn-danger text-white" onClick={handleAccountShow}>Pledge</button>
          {pledgeCount === 0 ?
            <button className="btn btn-transparent text-dark border-dark" onClick={NoPLedge}>No Pledges
              <span className='topCounterPledge'>{pledgeCount}</span>
            </button>
            :
            <Link to='/active-pledges' className='text-decoration-none text-dark'>
              <button className="btn btn-transparent text-dark border-dark">Active Pledges
                <span className='topCounterPledge'>{pledgeCount}</span>
              </button>
            </Link>
          }


        </div>
      </div>



      {/* moodals data */}

      {/* modal to show accounts */}
      <Modal show={showAddAccounts} onHide={handleAddAccountClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Add Account</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ height: "fit-content" }}>
          <Form className='makePledgeForm' onSubmit={handleAddNewProfileAccount}>
            <input
              type='text'
              placeholder='Enter Name'
              className='input-field'
              onChange={(e) => setUserProfileName(e.target.value)}
              style={{ fontSize: "14px" }}
            />
            <FormSelect onChange={handleUserProfileRelationChange} style={{ fontSize: "14px" }}
            >
              <option selected disabled value=''>Select relation</option>
              <option value='child'>Child</option>
              <option value='spouse'>Spouse</option>
              <option value='other'>Other</option>
            </FormSelect>
            <label style={{ fontSize: "10px" }}>Phone Number (Optional)</label>
            <PhoneNumberValidation style={phoneStyles} placeholder='Enter Phone Number (Optional)' />

            <Button style={{ fontSize: '14px' }} className="btn btn-sm bg-transparent text-danger border-danger photoOptional" onClick={() => document.getElementById('imageUpload').click()}>Select Photo (Optional)</Button>
            <br />
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
            {images && images instanceof File && (
              <div>
                <Cropper
                  image={URL.createObjectURL(images)}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={handleCropChange}
                  onZoomChange={handleZoomChange}
                  onCropComplete={onCropComplete}
                />
              </div>
            )}
            {cropImage instanceof Blob && (
              <div>
                <img src={URL.createObjectURL(cropImage)} alt="Cropped Image" style={{ width: "15vw" }} />
              </div>
            )}

          </Form>

        </Modal.Body>
        <Modal.Footer>
          {images && images instanceof File ? (

            <p onClick={handleCropImage}>Crop Image</p>
          ) : (
            <>
              <p className='text-dark' onClick={handleAddAccountClose}>Close</p>
              <p className='text-danger' onClick={handleAddNewProfileAccount}>Save</p>
            </>
          )}


        </Modal.Footer>

      </Modal>

      {/* modal to add account */}

      <Modal show={showAccounts} onHide={handleAccountClose} centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <Form className='makePledgeForm'>
            {userProfile.length > 0 ?
              <p style={{ fontSize: "14px" }}>Select Account</p>
              :
              <><p style={{ fontSize: "14px" }}>Kindly Add An Account</p>
              </>
            }
            <span style={{ fontSize: "12px", marginBottom: "0.8rem" }}>Please select the account you want to pledge for</span>
            <div className='d-flex mt-2 mb-0'>
              <Button style={{ fontSize: "13px" }} className={`btn btn-sm  text-white  me-4 ${AccountType === 'mine' ? 'btn-danger text-white' : 'bg-transparent text-danger  border-danger'} `}
                onClick={() => handleChangeAccountType('mine')}>My Account</Button>

              <Button style={{ fontSize: "13px" }} className={`btn btn-sm  ${AccountType === 'other' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                onClick={() => handleChangeAccountType('other')}>Other Account</Button>
            </div>



            {AccountType === 'other' ?

              <>
                {userProfile.length > 0 ?
                  <FormSelect style={{ fontSize: "13px" }} onChange={handleUserProfileChange}>
                    <option selected disabled value=''>Select Account</option>

                    {userProfile.map((account) => (
                      !specialProfiles.includes(account.id) && (
                        <option key={account.id} value={account.id}>
                          {account.profile_name}
                        </option>
                      ))
                    )}


                  </FormSelect>
                  :
                  <option selected disabled></option>
                }

                <div className='d-flex justify-content-between'>
                  <span
                    style={{
                      fontSize: "12px",
                      paddingTop: "0.5rem",
                      cursor: "pointer"
                    }}
                    className='btn btn-sm btn-dark text-white'
                    onClick={handleAddAccountShow}
                  >
                    Add New Account
                  </span>


                </div>
              </>

              :
              <>
              </>
            }


          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-sm btn-secondary text-white' onClick={handleAccountClose}>Close</Button>
          <Button className='btn btn-sm btn-danger text-white' onClick={showPledgeOpen}>Make Pledge</Button>
        </Modal.Footer>

      </Modal>

      {/* modals to show the data */}
      <Modal show={showPledge} onHide={showPledgeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger'>Make pledge </Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ height: "70vh", overflowY: "scroll", overflowX: "hidden" }}>
          <Form className='makePledgeForm' onSubmit={handleMakePlede}>
            <div className='d-flex'>

              <Button className={`btn btn-sm  me-4 ${pledgeType === 'monetary' ? 'btn-danger text-white' : 'bg-transparent text-danger  border-danger'} `}
                onClick={() => handleChangePledgeType('monetary')}>Monetary</Button>
              {AccountType === 'other' ?

                <></>
                :

                <Button className={`btn btn-sm  ${pledgeType === 'in-kind' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                  onClick={() => handleChangePledgeType('in-kind')}>In-Kind</Button>
              }
            </div>


            {pledgeType === 'monetary' ?
              monetaryCount !== 0 ?
                <>
                  <span>You can only have one monetary pledge for this account</span>
                  <br></br>
                  <Link className='mt-3' to={`/pledge/${monetaryCountPledge.payment_code}`}>
                    <Button className='btn btn-sm btn-dark text-white'>View Pledge</Button>

                  </Link>

                </>
                :
                <>


                  <p>Enter pledge amount <b className='text-danger'>*</b></p>

                  <div>
                    <select value={pledgeFormData.currency}
                      onChange={handlePledgeInput}
                      className='kshInput'
                      style={{ width: "17%", height: "5vh" }}
                      name='currency'>
                      <option value='KSH'>KSH</option>
                      <option value='USD'>USD</option>
                      <option value='EUR'>EUR</option>
                      <option value='GBP'>GBP</option>
                    </select>

                    <input
                      type='number'
                      className='amountKshInput'
                      value={pledgeFormData.pledgeAmount}
                      onChange={handlePledgeInput}
                      name='pledgeAmount'
                      style={{ width: "80%" }}
                      required
                    />
                  </div>
                  {pledgeFormData.currency !== 'KSH' && pledgeFormData.pledgeAmount &&
                    <p className='text-danger' style={{ fontSize: "10px", textAlign: "right" }}>KSH {Math.round(valueKSH).toLocaleString('en-US')}</p>
                  }
                  <p>Select the mode of redemption</p>
                  <div className='d-flex'>
                    <Button className={`btn btn-sm  me-4 ${modeType === 'one' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                      onClick={() => handleChangeModeType('one')}>One-Off</Button>

                    <Button className={`btn btn-sm  ${modeType === 'regular' ? 'btn-danger text-white' : 'bg-transparent text-danger border-danger'} `}
                      onClick={() => handleChangeModeType('regular')}>Regular</Button>
                  </div>
                </>

              :
              <>
                {idNumber ?
                  <></>
                  :
                  <>
                    <p>Kindly enter your {UserType !== 'corporate' ? <>identification Number</> : <>Kra pin</>} <b className='text-danger'>*</b></p>

                    <div>
                      <input
                        type='text'
                        className='amountKshInput'
                        value={userUniqueIdentifier}
                        onChange={(event) => setUserUniqueIdentifier(event.target.value)}
                        name='userUniqueIdentifier'
                        style={{ width: "100%" }}
                        required
                      />
                    </div>
                  </>
                }


                <p>Describe the In-Kind contribution</p>
                <textarea
                  placeholder='Enter description'
                  row={4}
                  value={pledgeFormData.in_kind_name}
                  onChange={handlePledgeInput}
                  name='in_kind_name'
                >

                </textarea>
                <p>Enter estimated value of In-Kind contribution<b className='text-danger'>*</b></p>
                <div>
                  <select value={pledgeFormData.currency}
                    onChange={handlePledgeInput}
                    className='kshInput'
                    style={{ width: "17%", height: "5vh" }}
                    name='currency'
                    required
                  >
                    <option value='KSH'>KSH</option>
                    <option value='USD'>USD</option>
                    <option value='EUR'>EUR</option>
                    <option value='GBP'>GBP</option>

                  </select>

                  <input
                    type='number'
                    className='amountKshInput'
                    value={pledgeFormData.estimated_amount}
                    onChange={handlePledgeInput}
                    name='estimated_amount'
                    style={{ width: "80%" }}
                  />
                </div>
              </>
            }

            {pledgeType === 'monetary' ?
              modeType === 'regular' ?
                <>
                  <p>Select redemption frequency  <b className='text-danger'>*</b></p>
                  <FormSelect name='payment_frequency' onChange={handlePledgeInput} value={pledgeFormData.payment_frequency}>
                    <option value='' selected disabled>Select Frequency</option>
                    <option value='weekly'>Every Week</option>
                    <option value='2weeks'>Every 2 Weeks</option>
                    <option value='monthly'>Every Month</option>
                    <option value='3months'>Every 3 Months</option>
                    <option value='6months'>Every 6 Months</option>
                    <option value='yearly' >Every Year</option>
                  </FormSelect>

                  {pledgeFormData.payment_frequency && (
                    <>
                      <p>Enter redemption start date  <b className='text-danger'>*</b></p>

                      <div className='border-danger input-container' style={{ width: "100%", borderRadius: "6px" }}>
                        <Image
                          src={Calendar}
                          alt="Calendar"
                          style={{ width: '5%', marginRight: '0.5rem', marginLeft: '0.3rem', cursor: 'pointer' }}
                        >
                        </Image>


                        {selectedDate ? (
                          <span style={{ marginLeft: "0.5rem" }} className='fw-bold text-danger mt-1'>{selectedDate}</span>
                        ) : (
                          <span style={{ marginLeft: "0.5rem" }} className='fw-bold text-danger mt-1 p-2' >Kindly select a date</span>
                        )}

                        <input
                          type='date'
                          onChange={handleDateChange}
                          min={new Date().toISOString().split('T')[0]}
                        />


                      </div>

                      {pledgeFormData.pledgeAmount !== '' && (
                        <>
                          <p>Enter the amount of each installment <b className='text-danger'>*</b></p>
                          <input
                            type='number'
                            className='inputNumberInstallement'
                            onChange={handlePledgeInput}
                            name='installment'
                            value={pledgeFormData.installment}
                            disabled={!isInstallmentEditable}
                          />
                        </>
                      )}
                    </>
                  )}

                </>
                :
                <></>
              :
              <></>
            }

            {pledgeType === 'monetary' ?
              monetaryCount !== 0 ?
                <></>
                :
                <>
                  <p className='mt-2'>Opt in to pledge reminders</p>
                  <div>
                    <>
                      <input
                        type='checkbox'
                        checked={smsChecked}
                        onChange={handleSmsChange}
                      />
                      <span className='checked'>via SMS</span>
                    </>
                    <>
                      <input
                        type='checkbox'
                        checked={emailChecked}
                        onChange={handleEmailChange}
                      />
                      <span className='checked'>via Email</span>
                    </>
                  </div>
                </>
              :
              <>

              </>
            }

          </Form>


        </Modal.Body>
        <Modal.Footer>

          {pledgeType === 'monetary' ?
            monetaryCount !== 0 ?
              <></>
              :
              <Button className='btn btn-sm btn-danger text-white' onClick={showSavePledge}>Make Pledge</Button>
            :
            <Button className='btn btn-sm btn-danger text-white' onClick={showSavePledge}>Make Pledge</Button>
          }

          {
            pledgeFormData.installment === "" ?
              <>
              </>
              :
              pledgeType === 'monetary' && selectedDate && pledgeFormData.installment &&
              <Button className='btn btn-sm btn-dark text-white' onClick={showScheduleOpen}>View Schedule</Button>

          }
        </Modal.Footer>

      </Modal>

      {/* confirmation modal */}
      <Modal show={confirmSavePledge} onHide={closeSavePledge}>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Confirm Pledge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pledgeType === 'monetary' ?
            pledgeFormData.pledgeAmount > 0 ?
              <p>
                Proceed to make a pledge
                {pledgeFormData.pledgeAmount !== null && pledgeFormData.pledgeAmount !== undefined ?
                  <span>
                    {" "} of <span className='text-danger'>{pledgeFormData.currency}. {parseInt(pledgeFormData.pledgeAmount).toLocaleString('en-US')} </span>
                    {pledgeFormData.installment !== null && pledgeFormData.installment !== undefined ?
                      <span>
                        {numberOfInstallments !== 0 && numberOfInstallments !== null && numberOfInstallments !== undefined && numberOfInstallments !== Infinity ?
                          <>redeemed in <span className='text-danger'>{numberOfInstallments}</span> installments </>
                          :
                          <></>
                        }
                        {pledgeFormData.installment ?
                          <>
                            of < span className='text-danger'>{pledgeFormData.currency}. {pledgeFormData.installment && (parseInt(pledgeFormData.installment).toLocaleString('en-US'))} </span>
                            each <span className='text-danger' style={{ textTransform: "capitalize" }}>
                              {pledgeFormData.payment_frequency !== null && pledgeFormData.payment_frequency !== undefined
                                ? (pledgeFormData.payment_frequency === 'weekly' ? 'week' :
                                  pledgeFormData.payment_frequency === 'monthly' ? 'month' :
                                    pledgeFormData.payment_frequency === 'yearly' ? 'year' : pledgeFormData.payment_frequency)
                                : ''}
                            </span>
                          </>

                          :
                          <></>
                        }
                        {selectedDate !== null && selectedDate !== undefined && formattedEndDate !== null && formattedEndDate !== undefined ?
                          <span> starting on <span className='text-danger'>{formattedStartDate}</span> and ending on <span className='text-danger'>{formattedEndDate}</span></span>
                          :
                          <span></span>
                        }
                      </span>
                      :
                      <span></span>
                    }
                  </span>
                  :
                  <span></span>
                }
              </p>


              :
              <p>Proceed to make your {pledgeType} pledge</p>
            : pledgeFormData.estimated_amount > 0 ?
              <p>Proceed to make your {pledgeType}  pledge with an estimation of <span className='text-danger'>{pledgeFormData.currency}. {parseInt(pledgeFormData.estimated_amount).toLocaleString('en-US')} </span></p>
              :
              <p>Proceed to make your {pledgeType} pledge</p>
          }

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeSavePledgeReturn}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleMakePlede}>
            Confirm
          </Button>
        </Modal.Footer>

      </Modal>

      {/* modal for viewing the schedule */}
      <Modal show={showModalSchedule} onHide={showScheduleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Redemption Schedule</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex justify-content-between'>
            <Card className='d-flex topDisplayCard endDate'>
              <Card.Body>
                <i className='bi bi-calendar-week me-2'></i>
                <div>
                  <p>Pledge End Date</p>
                  <span>

                    <PaymentCalendar

                      installmentAmount={pledgeFormData.installment}
                      paymentFrequency={pledgeFormData.payment_frequency}
                      totalAmount={pledgeFormData.pledgeAmount}
                      startDate={selectedDate}
                      displayType='end_date'
                    />

                  </span>
                </div>
              </Card.Body>

            </Card>
            <Card className='d-flex topDisplayCard nextDate'>
              <Card.Body>
                <i className='bi bi-calendar2-check me-2'></i>
                <div>
                  <p>Next Redemption Date</p>
                  <span>
                    <PaymentCalendar

                      installmentAmount={pledgeFormData.installment}
                      paymentFrequency={pledgeFormData.payment_frequency}
                      totalAmount={pledgeFormData.pledgeAmount}
                      startDate={selectedDate}
                      displayType='next_payment_date'
                    />
                  </span>
                </div>
              </Card.Body>

            </Card>

            <Card className='d-flex topDisplayCard frequencyDate'>
              <Card.Body>
                <i className='bi bi-calendar-plus me-2'></i>
                <div>
                  <p>Redemption frequency</p>
                  <span>
                    {pledgeFormData.payment_frequency}
                  </span>
                </div>
              </Card.Body>

            </Card>

          </div>


          <Card className='TopDisplayMetrics'>
            <Card.Body>
              <div>
                <span>Total</span>
                {pledgeFormData.pledgeAmount && (
                  <p>Ksh.{pledgeFormData.pledgeAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                )}


              </div>

              <div>
                <span>Installments</span>
                {pledgeFormData.installment && (
                  <p>Ksh.{pledgeFormData.installment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                )}


              </div>

              <div>
                <span>Balance</span>
                {pledgeFormData.pledgeAmount && (
                  <p>Ksh.{pledgeFormData.pledgeAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                )}
              </div>
            </Card.Body>

          </Card>

          <PaymentCalendar

            installmentAmount={pledgeFormData.installment}
            paymentFrequency={pledgeFormData.payment_frequency}
            totalAmount={pledgeFormData.pledgeAmount}
            startDate={selectedDate}
            displayType='calendar'
          />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={showScheduleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for sharing */}

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Share this campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex">
            <div
              className="text-decoration-none text-dark w-50"
              style={{
                cursor: "pointer",
              }}
              onClick={() => handlePlatform("whatsapp")}
            >
              <Col
                md={6}
                sm={12}

                className="d-flex shareButtons"
                style={{
                  border: "1px solid #9a1712",
                  width: "91%",
                  marginInline: "0.5rem",
                  marginTop: "1rem",
                  borderRadius: "10px",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
              >
                <Image
                  src={Whatsapp}
                  className="mb-2 me-2 mt-2 ms-2"
                  style={{
                    borderRadius: "10px",
                    width: "auto",
                    height: "5vh",
                  }}
                />
                <p className="mt-3">Share on WhatsApp</p>
              </Col>
            </div>

            <div
              className="text-decoration-none text-dark w-50"
              style={{
                cursor: "pointer",
              }}
              onClick={() => handlePlatform("facebook")}
            >
              <Col
                md={6}
                sm={12}

                className="d-flex shareButtons"
                style={{
                  border: "1px solid #9a1712",
                  width: "91%",
                  marginInline: "0.5rem",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
                onClick={() => shareFacebook()}
              >
                <Image
                  src={Facebook}
                  className="mb-2 me-2 mt-2 ms-2"
                  style={{
                    borderRadius: "10px",
                    width: "auto",
                    height: "5vh",
                  }}
                />
                <p className="mt-3">Share on Facebook</p>
              </Col>
            </div>
          </Row>

          <Row className="d-flex">
            <div
              className="text-decoration-none text-dark w-50"
              style={{
                cursor: "pointer",
              }}
              onClick={() => handlePlatform("gmail")}
            >
              <Col
                md={6}
                sm={12}

                className="d-flex shareButtons"
                style={{
                  border: "1px solid #9a1712",
                  width: "91%",
                  marginInline: "0.5rem",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
              >
                <Image
                  src={Mail}
                  className="mb-2 me-2 mt-2 ms-2"
                  style={{
                    borderRadius: "10px",
                    width: "auto",
                    height: "5vh",
                  }}
                />
                <p className="mt-3">Share on Gmail</p>
              </Col>
            </div>

            <div
              className="text-decoration-none text-dark w-50"
              style={{
                cursor: "pointer",
              }}
              onClick={() => handlePlatform("x")}
            >
              <Col
                md={6}
                sm={12}

                className="d-flex shareButtons"
                style={{
                  border: "1px solid #9a1712",
                  width: "91%",
                  marginInline: "0.5rem",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
              >
                <Image
                  src={Twitter}
                  className="mb-2 me-2 mt-2 ms-2"
                  style={{
                    borderRadius: "10px",
                    width: "auto",
                    height: "5vh",
                  }}
                />
                <p className="mt-3">Share on Twitter</p>
              </Col>
            </div>
          </Row>

          <Row className="d-flex">
            <div
              className="text-decoration-none text-dark w-50"
              style={{
                cursor: "pointer",
              }}
              onClick={() => handlePlatform("instagram")}
            >
              <Col
                md={6}
                sm={12}
                className="d-flex shareButtons"
                style={{
                  border: "1px solid #9a1712",
                  width: "91%",
                  marginInline: "0.5rem",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
              >
                <Image
                  src={Instagram}
                  className="mb-2 me-2 mt-2 ms-2"
                  style={{
                    borderRadius: "10px",
                    width: "auto",
                    height: "5vh",
                  }}
                />
                <p className="mt-3">Share on Instagram</p>
              </Col>
            </div>

            <Col
              md={6}
              sm={12}
              className='shareButtons'

              style={{
                border: "1px solid #9a1712",
                width: "44%",
                marginInline: "1.1rem",
                marginTop: "1rem",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "inherit")
              }
              onClick={() => handlePlatform("copy_link")}
            >
              <div className="d-flex">
                <Image
                  src={Paperclip}
                  className="mb-2 me-2 mt-2"
                  style={{
                    borderRadius: "10px",
                    width: "auto",
                    height: "5vh",
                  }}
                />
                <p className="mt-3">Copy the link </p>
              </div>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal to make redemption of pledge */}
      <Modal size='lg' show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold'>Make Redemption</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h6 style={{ textTransform: "capitalize", placeContent: "center", display: "flex" }}>{campaignStatement.campaign_description}</h6> */}
          <p style={{ fontSize: "14px", fontWeight: "600", marginBottom: "0.5rem", textAlign: "left" }}>Select Mode Of Payment</p>

          <div className='d-flex flex-wrap' style={{ alignItems: "center" }}>

            <div className='mt-2 d-flex '>
              <div className={'ModeofPayment' + (selectedPaymentMethod === 'MPESA' ? ' selectedPayment' : '')} onClick={() => handleShowModal('MPESA')}>
                <Image src={Mpesa} />
                <p>MPESA</p>
              </div>

              <div className={'ModeofPayment' + (selectedPaymentMethod === 'CHEQUE' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CHEQUE')}>
                <Image src={Cheque} />
                <p>CHEQUE</p>
              </div>

              <div className={'ModeofPayment' + (selectedPaymentMethod === 'CARD' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CARD')}>
                <Image src={Credit} />
                <p>CARD</p>
              </div>

              <div className={'ModeofPayment' + (selectedPaymentMethod === 'PAYPAL' ? ' selectedPayment' : '')} onClick={() => handleShowModal('PAYPAL')}>
                <Image src={PayPal} />
                <p>PAYPAL</p>
              </div>
              <div className={'ModeofPayment' + (selectedPaymentMethod === 'BANK TRANSFER' ? ' selectedPayment' : '')} onClick={() => handleShowModal('BANK TRANSFER')}>
                <Image src={Bank} />
                <p>BANK TRANSFER</p>
              </div>



            </div>


          </div>

          {selectedPaymentMethod === 'MPESA' && (
            // Content for MPESA
            <>

              <div className='donation p-2'>
                <div className='d-flex' style={{ placeContent: "center", width: "24vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                  <p className='kshP'>Ksh</p>
                  <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "10rem", backgroundColor: "transparent", border: "0", outline: "0" }} />

                </div>

                <div className='d-flex flex-wrap'>
                  {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                    <Button
                      key={index}
                      className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                      onClick={() => handleButtonClick(index, amount)}
                      onChange={handleAmountChange}

                    >
                      Ksh. {amount.toLocaleString()}
                    </Button>
                  ))}
                </div>


              </div>
            </>
          )}
          {selectedPaymentMethod === 'CHEQUE' && (
            // Content for CHEQUE
            <>
              <div className='donation p-0'>
                <p className='fw-bold' style={{ fontSize: "12px" }}>Input Amount</p>

                <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                  <select value={chequeData.currency}
                    onChange={handlePledgeInput}
                    className='currency'
                    style={{ border: "0", color: "#9A1712" }}
                    name='currency'>
                    <option className='kshP' value='KSH'>KSH</option>
                    <option className='kshP' value='USD'>USD</option>
                    <option className='kshP' value='EUR'>EUR</option>
                    <option className='kshP' value='GBP'>GBP</option>
                  </select>
                  <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                </div>

                <div className='d-flex flex-wrap'>
                  {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                    <Button
                      key={index}
                      className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                      onClick={() => handleButtonClick(index, amount)}
                      onChange={handleAmountChange}

                    >
                      Ksh. {amount.toLocaleString()}
                    </Button>
                  ))}
                </div>


              </div>


              <p className='fw-bold' style={{ fontSize: "12px" }}>Cheque Number</p>
              <input
                className='chequeNumber'
                style={{ width: "35vw" }}
                type='text'
                placeholder='Cheque Number'
                onChange={handlePledgeInput}
                name='chequeNumber'
              />

              <p className='fw-bold' style={{ fontSize: "12px" }}>Image Of Cheque(optional)</p>
              <Button style={{ fontSize: "14px" }} className='btn btn-sm bg-transparent text-danger border-danger' onClick={() => document.getElementById('imageUpload').click()}>Add cheque photo</Button>

              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleChangeImage}
              />
              {images && images instanceof File && (
                <>
                  <img
                    src={URL.createObjectURL(images)}
                    alt=""
                    className=""
                    style={{ width: '20vw', height: '20vh', margin: "0 auto", display: "block" }}
                  />

                </>
              )}


              <div>
                <p className='fw-bold' style={{ fontSize: "12px" }}>Date</p>
                <input
                  className='chequeNumber'
                  onChange={handlePledgeInput}
                  value={chequeData.transaction_date}
                  type='date'
                  name='transaction_date'
                  placeholder='Payment Date'
                  max={new Date().toISOString().slice(0, 10)}
                />

              </div>

            </>
          )}
          {selectedPaymentMethod === 'CARD' && (
            // Content for CARD
            <p className='text-center mt-3 text-danger fw-bold' style={{ fontSize: "20px" }}>COMING SOON</p>)}
          {selectedPaymentMethod === 'PAYPAL' && (
            // Content for PAYPAL
            <p className='text-center mt-3 text-danger fw-bold' style={{ fontSize: "20px" }}>COMING SOON</p>
          )}
          {selectedPaymentMethod === 'BANK TRANSFER' && (
            // Content for BANK TRANSFER
            <>
              <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Select type of bank transer</p>

              <div className='mt-3 d-flex justify-content-between'>

                <div className={'ModeofPayment' + (bankTransferOption === 'PESALINK' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('PESALINK')}>
                  <Image src={Bank} style={{ width: "12%" }} />
                  <p>Pesalink</p>
                </div>
                <div className={'ModeofPayment' + (bankTransferOption === 'EFT' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('EFT')}>
                  <Image src={Bank} style={{ width: "12%" }} />
                  <p>EFT</p>
                </div>
                <div className={'ModeofPayment' + (bankTransferOption === 'RTGS' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('RTGS')}>
                  <Image src={Bank} style={{ width: "12%" }} />
                  <p>RTGS</p>
                </div>

              </div>

              <>
                <div className='donation p-0'>
                  <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount</p>

                  <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                    <select value={chequeData.currency}
                      onChange={handlePledgeInput}
                      className='currency'
                      style={{ border: "0", color: "#9A1712" }}
                      name='currency'>
                      <option className='kshP' value='KSH'>KSH</option>
                      <option className='kshP' value='USD'>USD</option>
                      <option className='kshP' value='EUR'>EUR</option>
                      <option className='kshP' value='GBP'>GBP</option>
                    </select>
                    <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                  </div>

                  <div className='d-flex flex-wrap'>
                    {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                      <Button
                        key={index}
                        className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                        onClick={() => handleButtonClick(index, amount)}
                        onChange={handleAmountChange}

                      >
                        Ksh. {amount.toLocaleString()}
                      </Button>
                    ))}
                  </div>


                </div>


                <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Transaction Number(Optional)</p>
                <input
                  className='chequeNumber'
                  style={{ width: "35vw" }}
                  type='text'
                  placeholder='Transaction Number'
                  onChange={handlePledgeInput}
                  value={chequeData.chequeNumber}
                  name='chequeNumber'
                />

                <div>
                  <p className='fw-bold' style={{ fontSize: "12px" }}>Date</p>
                  <input
                    className='chequeNumber'
                    onChange={handlePledgeInput}
                    value={chequeData.transaction_date}
                    type='date'
                    name='transaction_date'
                    placeholder='Payment Date'
                    max={new Date().toISOString().slice(0, 10)}
                  />

                </div>

              </>
            </>

          )}


          {selectedPaymentMethod === 'PAYPAL' || selectedPaymentMethod === 'CARD' || selectedPaymentMethod === null ? null :
            selectedPaymentMethod && (
              <p className='text-info mt-3' style={{ textTransform: "capitalize", fontSize: "11px", marginInline: "3rem" }} onClick={handleShow}>
                Tap to share a link to another person to contribute for you
              </p>
            )

          }

        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-4 w-25' onClick={handleRedeemAdd}>Redeem</Button>

        </Modal.Footer>
      </Modal>


      {/* showing error message */}
      <Modal show={addErrorMessage} onHide={() => setAddErrorMessage(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-danger fw-bold m-auto'>Change frequency or installment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The pledge end date {formattedEndDate} exceeds the campaign expiry {formattedEndsOn}. Please adjust the installment of frequency</p>

        </Modal.Body>




      </Modal>

    </div >
  );
};

export default NewHomePage;
