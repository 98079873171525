import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Row, Col, Image, Modal, Table, Form, FormControl, Tab, Nav, Spinner, FormSelect } from 'react-bootstrap'
import '../../assets/scss/PledgeCard.scss';
import PieChart from './PieChart';
import Citam from '../../assets/img/citam_default.png'
import Coin from '../../assets/img/coin-stack.png'
import Whatsapp from '../../assets/img/whatsapp.png'
import Facebook from '../../assets/img/facebook.png'
import Twitter from '../../assets/img/twitter.png'
import Instagram from '../../assets/img/instagram.png'
import Paperclip from '../../assets/img/paperclip.png'
import Mail from '../../assets/img/gmail.png'
import Mpesa from '../../assets/img/mpesa.jpeg'
import Cheque from '../../assets/img/cheque.png'
import Credit from '../../assets/img/credit-card.png'
import Cash from '../../assets/img/money.png'
import User from '../../assets/img/user.png'
import User1 from '../../assets/img/user_1.png'
import { Link, useParams } from 'react-router-dom'
import PayPal from '../../assets/img/paypal.png'
import Bank from '../../assets/img/bank.png'
import { jwtDecode } from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import Donation from '../main/Donation';
import { saveAs } from 'file-saver';
import Excel from '../../assets/img/excel.png'
import Pdf from '../../assets/img/pdf-file.png'
import PaymentCalendar from './PaymentCalendar';
import Select from 'react-select'

const PledgeCard = ({ title, category, raisedAmount, days_remaining, description, contributions, goalAmount, inkindAmount, image, ends_on }) => {
  const percentagePledge = (raisedAmount / goalAmount) * 100;
  const percentagePledgeInkind = (raisedAmount / inkindAmount) * 100;
  const currentBalance = (goalAmount - raisedAmount)
  const [show, setShow] = useState(false);
  const [isLoading, set] = useState(false);
  const { id } = useParams()

  const banks = [
    {

      "name": "KCB"
    },
    {

      "name": "Standard Chartered"
    },
    {

      "name": "ABSA"
    },
    {

      "name": "NCBA"
    },
    {

      "name": "Prime Bank"
    },
    {

      "name": "Coop Bank"
    },
    {

      "name": "NBK"
    },
    {

      "name": "M-Oriental"
    },
    {

      "name": "Citi Bank"
    },
    {

      "name": "Middle East Bank"
    },
    {

      "name": "Bank of Africa"
    },
    {

      "name": "Consolidated Bank"
    },
    {

      "name": "Credit Bank"
    },
    {

      "name": "Stanbic Bank"
    },
    {

      "name": "ABC Bank"
    },
    {

      "name": "Ecobank"
    },
    {

      "name": "Paramount"
    },
    {

      "name": "Kingdom Bank"
    },
    {

      "name": "GT Bank"
    },
    {

      "name": "Victoria Bank"
    },
    {

      "name": "Guardian Bank"
    },
    {

      "name": "I&M Bank"
    },
    {

      "name": "Housing Finance"
    },
    {

      "name": "DTB"
    },
    {

      "name": "Mayfair Bank"
    },
    {

      "name": "Sidian Bank"
    },
    {

      "name": "Equity Bank"
    },
    {

      "name": "Family Bank"
    },
    {

      "name": "Gulf African Bank"
    },
    {
      "name": "Premier Bank Kenya"
    },
    {

      "name": "Dubai Islamic Bank"
    },
    {

      "name": "UBA"
    },
    {

      "name": "KWFT"
    },
    {

      "name": "Stima Sacco"
    },
    {

      "name": "Telkom Kenya"
    },
    {

      "name": "Choice Microfinance Bank"
    }
  ]

  const [campaignStatement, setCampaignStatements] = useState({})

  const [showModalSchedule, setShowModalSchedule] = useState(false)

  const showScheduleOpen = () => {
    setShowModalSchedule(true)
  }
  const showScheduleClose = () => {
    setShowModalSchedule(false)
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fullName, setFullName] = useState('');

  const [copied, setCopied] = useState(false);

  const url = `https://tig.citam.org/#/shared-pledge/${id}`;

  const handleCopyLink = () => {
    const textToCopy = `${url}`;
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopied(true);
    toast.success("Linked is copied");
  };

  const handleCopyLinkCode = () => {
    const textToCopy = `${id}`;
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopied(true);
    toast.success("Code has been copied");
  }
  const websiteUrl = `https://tig.citam.org/#/shared-pledge/${id}`;
  const appUrl = "https://play.google.com/store/apps/details?id=com.usalamatechnology.citam_fundraise_app";

  let pledgeAmountString;
  if (goalAmount) {
    const pledgeAmountString = goalAmount.toLocaleString('en-US')
  }

  let pledgeRaisedString;
  if (raisedAmount) {
    const pledgeRaisedString = raisedAmount.toLocaleString('en-US')

  }
  const descriptionNew = 'I welcome you to contribute towards my pledge for CITAM’s Together in Generosity (TIG) initiative. The campaign is to raise funds/resources to facilitate the settling of 23 assemblies that are in tents while others are on leased premises.'

  const messageLink = `${descriptionNew}\nTo Assist In My Pledge Click The Link below\n${websiteUrl}`;


  const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
    messageLink
  )}`;

  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    messageLink
  )}`;

  const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    messageLink
  )}`;

  const instagramLink = "https://www.instagram.com/direct/inbox/";

  function shareFacebook() {
    let url = encodeURIComponent(messageLink);
    let title = "title";
    window.open(
      `http://www.facebook.com/sharer.php?u=${url}`,
      "pop up name",
      "width=600, height=600"
    );
  }

  const handlePlatform = (platform) => {
    var Platform = platform;

    if (platform === "whatsapp") {
      window.open(whatsappLink, "_blank");
    } else if (platform === "facebook") {
      // Handle Facebook logic here
      // ...
    } else if (platform === "gmail") {
      window.open(EmailLink);
    } else if (platform === "x") {
      window.open(twitterLink, "_blank");
    } else if (platform === "instagram") {
      window.open(instagramLink, "_blank");
    } else if (platform === "copy_link") {
      handleCopyLink();
    }

  };

  let fillColorClass;

  if (percentagePledge < 33) {
    fillColorClass = '#e74c3c';
  } else if (percentagePledge < 67) {
    fillColorClass = '#f39c12';
  } else {
    fillColorClass = '#2ecc71';

  }

  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token to get the user id
  const decodedToken = jwtDecode(accessToken);
  const userId = decodedToken.user_id;
  const [monthsToFutureDate, setMonthsToFutureDate] = useState(null);
  useEffect(() => {
    const currentDate = new Date();
    const futureDate = new Date(ends_on);
    const differenceInMonths = (futureDate.getFullYear() - currentDate.getFullYear()) * 12 + (futureDate.getMonth() - currentDate.getMonth());
    setMonthsToFutureDate(differenceInMonths);
  });

  const formatDate = (ends_on) => {
    const date = new Date(ends_on);
    const day = date.toLocaleDateString('en-GB', { day: 'numeric' });
    const month = date.toLocaleDateString('en-GB', { month: 'long' });
    const year = date.toLocaleDateString('en-GB', { year: 'numeric' });

    return { day, month, year };
  };
  const formattedDate = formatDate(ends_on);

  // countdown data
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const targetDate = new Date(ends_on).getTime();
      const now = new Date().getTime();
      const timeRemaining = targetDate - now;

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [ends_on]);

  const [pledgeStatement, setPledgeStatements] = useState([])
  const [contributorStatement, setContributorStatements] = useState([])
  const [userChurch, setUserChurch] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [phoneNumberOther, setPhoneNumberOther] = useState([])



  const [selectedBank, setSelectedBank] = useState(null);

  const optionList = banks.map(bank => ({
    value: bank.name,
    label: bank.name
  }));
  const handleSelectChange = selectedOption => {
    setSelectedBank(selectedOption);
  };

  useEffect(() => {

    serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
      .then((res) => {
        setPledgeStatements(res.data.results[0].pledge_statement)
        setContributorStatements(res.data.results[0].contributor)
        setCampaignStatements(res.data.results[0].campaign_data[0])

      })
      .catch((error) => {
        console.log(error)
      })

    // get the pledge data 
    serverUrl.get(`/pledge/active-pledges/?user_id=${userId}`, config)
      .then((res) => {
        // console.log(res.data)
      })
      .catch((error) => {
        console.log(error)
      })

    // get the other phone numbers
    serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
      .then((res) => {
        setPhoneNumberOther(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

    serverUrl
      .get(`/user/user-details/?id=${userId}`, config)
      .then((res) => {
        // get the full name of the user
        setFullName(res.data.results[0].full_name);
        setPhoneNumber(res.data.results[0].phone_number);
        setUserPhoneNumber(res.data.results[0].phone_number)

      })
      .catch((error) => {
        console.log(error);
      });

    // get the church details of user
    serverUrl.get(`/churches/church-users-logo-name/?user_id=${userId}`, config)
      .then((res) => {
        setUserChurch(res.data.results[0])
      })
      .catch((error) => {
        console.log(error)
      })
  }, [id])

  // Inside your component
  const [showModal, setShowModal] = useState(false);

  // Function to handle showing the modal
  const handleShowModalNumber = () => {
    setShowModal(true);
  };

  // Function to handle adding the number
  const handleAddNumber = () => {
    const data = {
      user: userId,
      phone_number: phoneNumber,
      country_name: 'Kenya',
      country_code: '+254'
    }
    setIsLoading(true)
    serverUrl.post('/user/user-linked-phone-numbers/', data, config)
      .then((res) => {
        toast.success('Succesfully added the number')
        serverUrl.get(`/user/user-linked-phone-numbers?user=${userId}`, config)
          .then((res) => {
            setPhoneNumberOther(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })
        setShowModal(false);
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })

  };
  const [showOptions, setShowOptions] = useState(false);

  // Function to handle showing the options
  const handleShowOptions = () => {
    setShowOptions(true);
  };

  // Function to handle selecting a phone number from the options
  const handleSelectPhoneNumber = (selectedNumber) => {
    setPhoneNumber(selectedNumber);
    setShowOptions(false);
  };

  // JSX code for the options
  const phoneNumberOptions = phoneNumberOther.map((number, index) => (
    <option key={index} value={number.phone_number}>{number.phone_number}</option>
  ));

  const [modalShow, setModalShow] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('MPESA');

  const handleOpenRedeemModal = () => {
    setModalShow(true);

  }
  const handleShowModal = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setSelectedPaymentMethod('');
  };
  const [donationAmount, setDonationAmount] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [bankTransferOption, setBankTransferOption] = useState('PESALINK');
  const handleButtonClick = (index, amount) => {
    setActiveIndex(index);
    setDonationAmount(amount)
  };
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleBankTransferOptionChange = (option) => {
    setBankTransferOption(option);
  };

  let fillMobileColor;
  if (campaignStatement.pledge_status === "unfulfilled") {
    fillMobileColor = '#e74c3c';

  }
  else if (campaignStatement.pledge_status === 'partial') {
    fillMobileColor = '#f39c12';

  }
  else {
    fillMobileColor = '#2ecc71';

  }
  const handleBankTransferSelect = (option) => {
    handleBankTransferOptionChange(option);
    setSelectedPaymentMethod(option);
  };

  const [editPLedge, setEditPledge] = useState(false)

  const handleEdiPledge = () => {
    setEditPledge(true)
  }
  const ModalTrue = localStorage.getItem('modalSet')

  useEffect(() => {
    if (ModalTrue) {
      setEditPledge(true)

    }
    else {
      setEditPledge(false)

    }
  }, [ModalTrue])

  const handleEdiPledgeClose = () => {
    setEditPledge(false)
  }
  const [pledgeDataEdit, setPledgeDataEdit] = useState({
    inkindName: '',
    originalPledgeAmount: '',
    originalAmount: '',
    pledgeFrequency: '',
    currency: ""
  });

  useEffect(() => {
    setPledgeDataEdit({
      inkindName: campaignStatement.inkind_name || '',
      originalPledgeAmount: campaignStatement.in_kind_amount || '',
      pledgeFrequency: campaignStatement.pledge_frequency || '',
      originalAmount: campaignStatement.original_pledge_amount || '',
      currency: campaignStatement.currency || '',
    });
  }, [campaignStatement]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPledgeDataEdit({
      ...pledgeDataEdit,
      [name]: value,
    });
  };

  function calculateEndDate() {
    // Convert startDate to Date object
    const startDateObj = new Date(campaignStatement.installment_start_date);

    // Calculate the number of installments required
    let numberOfInstallments = Math.ceil(pledgeDataEdit.originalAmount / campaignStatement.installment);

    // Adjust frequency for months and years
    if (campaignStatement.payment_frequency === 'monthly') {
      numberOfInstallments *= 1;
    } else if (campaignStatement.payment_frequency === '3months') {
      numberOfInstallments *= 3;
    } else if (campaignStatement.payment_frequency === '6months') {
      numberOfInstallments *= 6;
    } else if (campaignStatement.payment_frequency === 'yearly') {
      numberOfInstallments *= 12;
    } else if (campaignStatement.payment_frequency === 'weekly') {
      numberOfInstallments *= 1 / (365 / 7); // Adjust based on number of weeks in a year
    } else if (campaignStatement.payment_frequency === '2weeks') {
      numberOfInstallments *= 1 / (365 / 14); // Adjust based on number of bi-weekly periods in a year
    } else {
      // Handle other frequencies
      numberOfInstallments *= 1; // Adjust accordingly
    }

    // Calculate the end date by adding the required number of installments
    const endDate = new Date(startDateObj);
    endDate.setMonth(endDate.getMonth() + numberOfInstallments);

    return endDate;
  }



  function calculateEndDate() {
    // Convert startDate to Date object
    const startDateObj = new Date(campaignStatement.installment_start_date);

    // Initialize endDate as a copy of startDateObj
    const endDate = new Date(startDateObj);

    // Calculate the number of installments required
    let numberOfInstallments = Math.ceil(pledgeDataEdit.originalAmount / campaignStatement.installment);
    if (pledgeDataEdit.pledgeFrequency) {

      // Adjust frequency for months and years
      if (pledgeDataEdit.pledgeFrequency === 'monthly') {
        endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments, endDate.getDate());
      } else if (pledgeDataEdit.pledgeFrequency === '3months') {
        endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments * 3, endDate.getDate());
      } else if (pledgeDataEdit.pledgeFrequency === '6months') {
        endDate.setFullYear(endDate.getFullYear(), endDate.getMonth() + numberOfInstallments * 6, endDate.getDate());
      } else if (pledgeDataEdit.pledgeFrequency === 'yearly') {
        endDate.setFullYear(endDate.getFullYear() + numberOfInstallments, endDate.getMonth(), endDate.getDate());
      } else if (pledgeDataEdit.pledgeFrequency === 'weekly') {
        endDate.setDate(endDate.getDate() + numberOfInstallments * 7); // Adjust based on number of weeks in a year
      } else if (pledgeDataEdit.pledgeFrequency === '2weeks') {
        endDate.setDate(endDate.getDate() + numberOfInstallments * 14); // Adjust based on number of bi-weekly periods in a year
      } else {

      }

      return endDate;
    }
  }

  function changePledge(e) {
    e.preventDefault()
    if (campaignStatement.pledge_type === 'monetary') {
      if (pledgeDataEdit.pledgeFrequency) {// Calculate the end date
        const endDate = calculateEndDate();
        // Extract year, month, and day from the endDate
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, '0');
        const day = String(endDate.getDate()).padStart(2, '0');

        // Construct the formatted date string "yyyy-mm-dd"
        const formattedEndDate = `${year}-${month}-${day}`;

        // Define the campaign end date
        const campaignEndDate = new Date(campaignStatement.expiry_date);
        if (endDate > campaignEndDate) {
          toast.error('The end date exceeds the campaign end date. Pledge cannot be created.');
          return;
        }
      }



      if (parseInt(pledgeDataEdit.originalAmount) < parseInt(campaignStatement.original_pledge_amount)) {
        toast.error('Sorry you can not enter a lower amount than what you pledged')
      }
      else {
        const data = {
          original_pledge_amount: pledgeDataEdit.originalAmount,
          amount: pledgeDataEdit.originalAmount,
        }
        if (pledgeDataEdit.pledgeFrequency) {
          data.payment_frequency = pledgeDataEdit.pledgeFrequency
          // data.installment_end_date = formattedEndDate
        }

        serverUrl.patch(`/pledge/${parseInt(campaignStatement.pledge_id)}`, data, config)
          .then((res) => {
            toast.success('Succesfully updated the details')
            setEditPledge(false)
            localStorage.removeItem('modalSet')

            serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
              .then((res) => {
                setPledgeStatements(res.data.results[0].pledge_statement)
                setContributorStatements(res.data.results[0].contributor)
                setCampaignStatements(res.data.results[0].campaign_data[0])
              })
              .catch((error) => {
                console.log(error)
              })
            window.location.reload()
          })
          .catch((error) => {
            console.log(error)
          })

      }


    }
    else {
      const data = {
        estimated_amount: pledgeDataEdit.originalPledgeAmount,
        in_kind_name: pledgeDataEdit.inkindName
      }
      serverUrl.patch(`/pledge/${parseInt(campaignStatement.pledge_id)}`, data, config)
        .then((res) => {
          toast.success('Succesfully updated the details')
          setEditPledge(false)

          serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
            .then((res) => {
              setPledgeStatements(res.data.results[0].pledge_statement)
              setContributorStatements(res.data.results[0].contributor)
              setCampaignStatements(res.data.results[0].campaign_data[0])
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }

  // get post the data for the redeemption
  function handleRedeemAdd(e) {
    const description = `id-${campaignStatement.campaign_id}-${id}`
    e.preventDefault()
    if (campaignStatement.pledge_type === 'monetary') {

      if (selectedPaymentMethod === 'MPESA') {
        let formattedPhoneNumber = phoneNumber.trim();
        if (formattedPhoneNumber.startsWith('0')) {
          formattedPhoneNumber = '+254' + formattedPhoneNumber.slice(1);
        }
        if (!formattedPhoneNumber.startsWith('+254')) {
          formattedPhoneNumber = '+254' + formattedPhoneNumber;
        }

        const data = {
          amount: donationAmount,
          phone_number: formattedPhoneNumber,
          reference: id,
          description: description,
          source: "web",
          updated_by: userId,
        }

        if (userChurch) {
          data.church = userChurch.id;
        }
        setIsLoading(true)
        serverUrl.post(`giving/checkout/`, data, config)
          .then((res) => {
            setSelectedPaymentMethod(null);
            setIsLoading(false)

            toast.success('An mpesa prompt has been sent to your phone enter pin to make payment');
            // Reload the page after 8 seconds
            setTimeout(() => {
              window.location.reload();
            }, 8000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else if (selectedPaymentMethod === 'CHEQUE') {
        let transactionDate = null; // Initialize transaction date to null

        if (chequeData && chequeData.transaction_date) {
          // If transaction date exists in chequeData
          transactionDate = new Date(chequeData.transaction_date); // Use the provided transaction date
        } else {
          // If transaction date is null, use the current date
          transactionDate = new Date(); // Current date
        }

        // Format the date to YYYYMMDD format
        const formattedDate = transactionDate.toISOString().slice(0, 10).replace(/-/g, '');


        const splitName = fullName.split(' ')
        const firstName = splitName[0];
        const lastName = splitName[splitName.length - 1];
        const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;
        if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
          toast.error('Please fill in all the required fields')
          return
        }

        const formData = new FormData();
        formData.append("payment_code", id);
        formData.append("cheque_number", chequeData.chequeNumber);
        formData.append("currency", chequeData.currency);
        formData.append("transcation_amount", parseInt(donationAmount));
        formData.append("transaction_date", chequeData.transaction_date);
        formData.append("transcation_time", formattedDate + '000000');
        formData.append("payment_channel", "cheque");
        formData.append("phone_number", phoneNumber);
        formData.append("status", "pending");
        formData.append("updated_by", userId);
        formData.append("source", "web");
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("middle_name", middleName);
        formData.append("bill_ref_number", id);


        // Check if cheque image is present
        if (images) {
          formData.append("cheque_image", images);
        }
        if (userChurch) {
          formData.append("church", userChurch.id);
        }
        setIsLoading(true)


        serverUrl.post(`/giving/create-ctb-transaction/`, formData)
          .then((res) => {
            toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
            setModalShow(false);
            setIsLoading(false)

            serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
              .then((res) => {
                setPledgeStatements(res.data.results[0].pledge_statement)
                setContributorStatements(res.data.results[0].contributor)
                setCampaignStatements(res.data.results[0].campaign_data[0])
                setDonationAmount(null)
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }

      else if (selectedPaymentMethod === 'CARD') {
        let transactionDate = null; // Initialize transaction date to null

        if (chequeData && chequeData.transaction_date) {
          // If transaction date exists in chequeData
          transactionDate = new Date(chequeData.transaction_date); // Use the provided transaction date
        } else {
          // If transaction date is null, use the current date
          transactionDate = new Date(); // Current date
        }

        // Format the date to YYYYMMDD format
        const formattedDate = transactionDate.toISOString().slice(0, 10).replace(/-/g, '');


        const splitName = fullName.split(' ')
        const firstName = splitName[0];
        const lastName = splitName[splitName.length - 1];
        const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;

        const formData = new FormData();
        formData.append("payment_code", id);
        formData.append("transcation_id", chequeData.chequeNumber);
        formData.append("currency", chequeData.currency);
        formData.append("transcation_amount", parseInt(donationAmount));
        formData.append("transaction_date", chequeData.transaction_date);
        formData.append("transcation_time", formattedDate + '000000');
        formData.append("payment_channel", "card");
        formData.append("phone_number", phoneNumber);
        formData.append("status", "pending");
        formData.append("updated_by", userId);
        formData.append("source", "web");
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("middle_name", middleName);
        formData.append("bill_ref_number", id);
        if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date) {
          toast.error('Please fill in all the required fields')
          return
        }

        // Check if cheque image is present
        if (images) {
          formData.append("cheque_image", images);
        }
        if (userChurch) {
          formData.append("church", userChurch.id);
        }
        setIsLoading(true)

        serverUrl.post(`/giving/create-ctb-transaction/`, formData)
          .then((res) => {
            setIsLoading(false)

            toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
            setModalShow(false);
            serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
              .then((res) => {
                setPledgeStatements(res.data.results[0].pledge_statement)
                setContributorStatements(res.data.results[0].contributor)
                setCampaignStatements(res.data.results[0].campaign_data[0])
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }

      else if (selectedPaymentMethod === 'BANK TRANSFER') {
        const formattedDate = chequeData.transaction_date.replace(/-/g, '');
        const splitName = fullName.split(' ')
        const firstName = splitName[0];
        const lastName = splitName[splitName.length - 1];
        const middleName = splitName.length > 2 ? splitName.slice(1, -1).join(" ") : null;

        const bankData = {
          payment_code: id,
          bank_payment_channel: bankTransferOption,
          bank_transaction_reference: chequeData.chequeNumber,
          currency: chequeData.currency,
          transcation_amount: donationAmount,
          transaction_date: chequeData.transaction_date,
          transcation_time: formattedDate + '000000',
          payment_channel: "bank",
          phone_number: phoneNumber,
          status: "pending",
          updated_by: userId,
          source: "web",
          first_name: firstName,
          last_name: lastName,
          bill_ref_number: id,
        };
        if (selectedBank) {
          bankData.bank = selectedBank.value
        }
        else {
          toast.error('Bank should not be null')
          return
        }
        if (!chequeData.chequeNumber || !donationAmount || !chequeData.transaction_date | !bankTransferOption || !selectedBank.value) {
          toast.error('Please fill in all the required fields')
          return
        }

        if (middleName) {
          bankData.middle_name = middleName

        }
        setIsLoading(true)


        serverUrl.post(`/giving/create-ctb-transaction/`, bankData)
          .then((res) => {
            toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
            setModalShow(false);
            setIsLoading(false)

            serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
              .then((res) => {
                setPledgeStatements(res.data.results[0].pledge_statement)
                setContributorStatements(res.data.results[0].contributor)
                setCampaignStatements(res.data.results[0].campaign_data[0])
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
    else {
      const data = {
        pledge: campaignStatement.pledge_id,
        status: "pending",
        value: donationAmount,
        description: inKindData.description
      }
      setIsLoading(true)

      serverUrl.post(`/giving/in-kind-donations/`, data, config)
        .then((res) => {
          setIsLoading(false)

          toast.success('Succesfully made the contribution. Kindly wait for the admin to approve and confim the transaction. ')
          setModalShow(false);
          serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}`, config)
            .then((res) => {
              console.log(res.data.results)
              setPledgeStatements(res.data.results[0].pledge_statement)
              setContributorStatements(res.data.results[0].contributor)
              setCampaignStatements(res.data.results[0].campaign_data[0])
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(erro)
        })
    }

  }



  const [showDropdown, setShowDropdownBank] = useState(false);
  const handleShowModalBank = () => {
    setShowDropdownBank(true);
  };
  const handleCloseModalBank = () => {
    setModalShow(false);
    setSelectedPaymentMethod('');
    setBankTransferOption('');
  };
  let combinedName;
  if (campaignStatement && campaignStatement.user_name) {
    const splitName = campaignStatement.user_name.split(' ');
    const firstName = splitName[0];
    let secondNameInitial = null;

    if (splitName.length > 1 && splitName[1].length > 0) {
      secondNameInitial = splitName[1].charAt(0);
    }

    combinedName = `${firstName} ${secondNameInitial !== null ? secondNameInitial + '.' : ''}`;
  } else {
    combinedName = '';
  }

  const [activeTab, setActiveTab] = useState('pledge');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [images, setImages] = useState([]);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setImages(file);

  };



  const [chequeData, setChequeData] = useState({
    chequeNumber: "",
    currency: "KSH",
    transaction_date: "",
    amount: "",
  })

  const [inKindData, setInkindData] = useState({
    description: "",


  })

  const handlePledgeInput = (e) => {
    const { name, value } = e.target;

    setChequeData({
      ...chequeData,
      [name]: value,
    });

    setInkindData({
      ...inKindData,
      [name]: value,
    });
  };

  // table functionalities 
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };
  const filteredData = pledgeStatement.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });
  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;

  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const exportCSV = () => {
    serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}&download_csv_pledge=true`, config)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Pledge Statement.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Release the blob
        toast.success('Successfully downloaded the CSV');
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // table display for contributrs
  const [searchQueryContributor, setSearchQueryContributor] = useState("");
  const [selectedRowContributor, setSelectedRowContributor] = useState(null);

  const handleSearchContributor = (event) => {
    setSearchQueryContributor(event.target.value);
    setSelectedRowContributor(null); // Reset selected row when user starts a new search
  };
  const filteredDataContributor = contributorStatement.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQueryContributor.toLowerCase());
  });
  const [currentContributorPage, setCurrentContributorPage] = useState(0);
  const itemsPerPageContributor = 5;

  const totalPagesContributor = Math.ceil(filteredDataContributor.length / itemsPerPageContributor);
  const startIndexContributor = currentContributorPage * itemsPerPageContributor;
  const endIndexContributor = currentContributorPage === totalPagesContributor - 1 ? filteredDataContributor.length : startIndexContributor + itemsPerPageContributor;

  const displayedItemsContributor = filteredDataContributor.slice(startIndexContributor, endIndexContributor);

  const handlePrevPageContributor = () => {
    setCurrentContributorPage(currentContributorPage - 1);
  };

  const handleNextPageContributor = () => {
    setCurrentContributorPage(currentContributorPage + 1);
  };
  const exportCSVContributor = () => {
    serverUrl.get(`/pledge/pledge-campaign-statement-list/?payment_code=${id}&download_csv_contributor=true`, config)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Contributor Statement.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Release the blob
        toast.success('Successfully downloaded the CSV');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className='mainPledge'>
      {/* on the web view */}
      <div className="pledgeDisplay">

        {/* <p className='categoryCampaign'>{category}</p> */}
        {fullName === campaignStatement.user_name ?

          <h1>My Pledge
            {goalAmount && campaignStatement.goalAmount >= 0 ?
              <> of {campaignStatement.pledge_currency}. {goalAmount.toLocaleString('en-US')} </>

              :
              <></>
            }
          </h1>
          :

          <h1>{combinedName}'s pledge
            {goalAmount && campaignStatement.goalAmount >= 0 ?
              <> of {campaignStatement.pledge_currency}. {goalAmount.toLocaleString('en-US')} </>
              :
              <></>
            }
          </h1>

        }


        <div className="image-container">
          {campaignStatement.pledge_type === 'monetary' ?
            <>
              <span className='pledgeTypeDisplay' style={{ textTransform: "capitalize", background: "#2ecc71" }}>{campaignStatement.pledge_type}</span>


            </>
            :
            <>
              <span className='pledgeTypeDisplay' style={{ textTransform: "capitalize", background: "#3498db" }}>{campaignStatement.pledge_type}</span>

            </>

          }
          <img src={image} alt="Placeholder" className='TopDisplayImage' />
          {fullName === campaignStatement.user_name ?
            <></>
            :
            <img src={campaignStatement.user_photo} className='userProfileTop'></img>


          }

          <div>
            {campaignStatement.pledge_frequency ?
              <Button className='topButtonPledge btn btn-sm btn-dark bg-dark text-white' style={{ marginRight: "5rem" }} onClick={showScheduleOpen}>View Schedule<i className='bi bi-eye ms-2'></i></Button>
              :
              <></>
            }

            <Button className='topButtonPledge btn btn-sm btn-danger' onClick={handleEdiPledge}>Edit <i className='bi bi-pen'></i></Button>

          </div>

        </div>

        {/* the below container */}
        <Container>
          {/* <div className='topDisplayMetrics'>
            <div className='d-flex justify-content-between' style={{ alignItems: "center", textAlign: "left", background: "white", borderRadius: "5px", marginRight: "1.5rem" }}>

              <div className='p-1' style={{ height: "8vh", width: "9vw", borderRadius: "5px" }}>
                <h2 className=' fw-bold mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "" }} >PLEDGE AMOUNT</h2>
                <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#2ecc71" }} className='fw-bold'>KSH. <b>{goalAmount.toLocaleString('en-US')}</b></p>

              </div>
              <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#bdc3c7", borderRight: "1px solid gray", height: "2vw", marginInline: "0.5rem", marginTop: "0.6rem" }}></p>

              <div className='p-1' style={{ height: "8vh", width: "9vw", borderRadius: "5px" }}>
                <h2 className=' fw-bold mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "", marginTop: "-0.2rem" }} >Contributed</h2>
                <p style={{ fontSize: "12px", fontWeight: "600", marginBottom: "0.1rem", color: "#f39c12" }} className='fw-bold'>KSH.
                  {raisedAmount &&
                    <b>{raisedAmount.toLocaleString('en-US')}</b>
                  }
                </p>
              </div>
              <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#bdc3c7", borderRight: "1px solid gray", height: "2vw", marginInline: "0.5rem", marginTop: "0.6rem" }}></p>

              <div className='p-1' style={{ height: "8vh", width: "9vw", borderRadius: "5px" }}>
                <h2 className=' fw-bold mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "", marginTop: "-0.2rem" }} >PLEDGE balance</h2>
                <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#e74c3c" }} className='fw-bold'>KSH. <b>{currentBalance.toLocaleString('en-US')}</b></p>
              </div>
            </div>

          </div> */}

          <div className='topDisplayMetrics'>
            <div className='d-flex justify-content-between' style={{ alignItems: "center", textAlign: "left" }}>

              <div className='p-1' style={{ background: "#ecf0f1", height: "8vh", width: "9vw", borderRadius: "5px" }}>
                <h2 className=' fw-bolder mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "" }} >PLEDGE AMOUNT</h2>
                <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#2ecc71" }} className='fw-bold'>KSH. <b>{goalAmount.toLocaleString('en-US')}</b></p>

              </div>
              <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#bdc3c7", borderRight: "1px solid gray", height: "2vw", marginInline: "0.5rem", marginTop: "0.6rem" }}></p>

              <div className='p-1' style={{ background: "#ecf0f1", height: "8vh", width: "9vw", borderRadius: "5px" }}>
                <h2 className=' fw-bolder mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "", marginTop: "-0.2rem" }} >Contributed</h2>
                <p style={{ fontSize: "12px", fontWeight: "600", marginBottom: "0.1rem", color: "#f39c12" }} className='fw-bold'>KSH.
                  {raisedAmount &&
                    <b>{raisedAmount.toLocaleString('en-US')}</b>
                  }
                </p>
              </div>
              <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#bdc3c7", borderRight: "1px solid gray", height: "2vw", marginInline: "0.5rem", marginTop: "0.6rem" }}></p>

              <div className='p-1' style={{ background: "#ecf0f1", height: "8vh", width: "9vw", borderRadius: "5px" }}>
                <h2 className=' fw-bolder mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "", marginTop: "-0.2rem" }} >PLEDGE balance</h2>
                <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#e74c3c" }} className='fw-bold'>KSH. <b>{currentBalance.toLocaleString('en-US')}</b></p>
              </div>
            </div>

          </div>



          {/* 
{element.style {
  width: 100%;
    border-right: 2px solid lightgray;
    position: relative;
    display: flex;
    justify-content: right;
}} */}
          <Card className='TopDisplayCard' style={{ backgroundColor: `${campaignStatement.pledge_type === 'monetary' ? '#2ecc71' : '#3498db'}` }}>

            <Card.Body>
              <p className='w-10 text-white fw-bold' style={{ width: "11%", alignSelf: "center", textTransform: "capitalize" }}>
                <span className='pb-3' style={{ textTransform: "capitalize" }}> {campaignStatement.pledge_type}</span><br></br>
                {campaignStatement.pledge_frequency ?
                  <span style={{ background: "#00000061", padding: "0.5rem", borderRadius: "5px", fontSize: "10px" }}>{campaignStatement.pledge_frequency}</span>
                  :
                  <span style={{ background: "#00000061", padding: "0.5rem", borderRadius: "5px", fontSize: "10px" }}>One Off</span>

                }
                <p className='text-white fw-bold mt-2' style={{ fontSize: "12px" }}>Pledge Code </p>
                <p className='text-white fw-bold' style={{ fontSize: "20px", marginTop: "-1rem" }} onClick={handleCopyLinkCode}>{id}</p>
              </p>

              <div className='d-flex justify-content-between bg-white w-100' style={{ marginBottom: "-1rem", marginRight: "-1rem" }}>

                <div className='ps-4' style={{ alignItems: "center", textAlign: "left" }}>
                  <h2 className='text-secondary  pt-3 fw-normal' style={{ fontSize: "10px", textTransform: "uppercase", color: "grey" }} >{campaignStatement.campaign_description}</h2>
                  <p style={{ fontSize: "13px", marginBottom: "0.1rem" }}>Pledge made on <b>{campaignStatement.pledge_created_date}</b></p>
                  <span style={{ fontSize: "12px", marginBottom: "0.1rem", color: "grey" }}>Pledge ends in:</span>
                  <div className='d-flex w-50' style={{ alignItems: "center", marginTop: "-0.5rem" }}>
                    <p className='mt-2' style={{ fontWeight: "bold", fontSize: "12px" }}>{monthsToFutureDate} Months</p>


                  </div>
                </div>

                {/* {campaignStatement.pledge_type === 'monetary' ?
                  <div className='d-flex justify-content-between bg-white' style={{ alignItems: "center", textAlign: "left" }}>

                    <div className='p-1' style={{ background: "#ecf0f1", height: "8vh", width: "9vw", borderRadius: "5px"}}>
                      <h2 className=' fw-normal mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "" }} >PLEDGE AMOUNT</h2>
                      <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#2ecc71" }} className='fw-bold'>KSH. <b>{goalAmount.toLocaleString('en-US')}</b></p>

                    </div>
                    <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#bdc3c7", borderRight: "1px solid gray", height: "2vw", marginInline: "0.5rem", marginTop: "0.6rem" }}></p>

                    <div className='p-1' style={{ background: "#ecf0f1", height: "8vh", width: "9vw", borderRadius: "5px" }}>
                      <h2 className=' fw-normal mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "", marginTop: "-0.2rem" }} >Contributed</h2>
                      <p style={{ fontSize: "12px", fontWeight: "600", marginBottom: "0.1rem", color: "#f39c12" }} className='fw-bold'>KSH.
                        {raisedAmount &&
                          <b>{raisedAmount.toLocaleString('en-US')}</b>
                        }
                      </p>
                    </div>
                    <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#bdc3c7", borderRight: "1px solid gray", height: "2vw", marginInline: "0.5rem", marginTop: "0.6rem" }}></p>

                    <div className='p-1' style={{ background: "#ecf0f1", height: "8vh", width: "9vw", borderRadius: "5px" }}>
                      <h2 className=' fw-normal mt-2' style={{ fontSize: "8px", lineHeight: "0", textTransform: "uppercase", color: "", marginTop: "-0.2rem" }} >PLEDGE balance</h2>
                      <p style={{ fontSize: "12px", marginBottom: "0.1rem", color: "#e74c3c" }} className='fw-bold'>KSH. <b>{currentBalance.toLocaleString('en-US')}</b></p>
                    </div>
                  </div>
                  :
                  campaignStatement.pledge_type === 'in-kind' && campaignStatement.in_kind_amount ?
                    <div className='d-flex justify-content-between' style={{ alignItems: "center", textAlign: "left" }}>
                      <div>
                        <h2 className='text-secondary  pt-3 fw-normal' style={{ fontSize: "10px", textTransform: "uppercase", color: "#ecf0f1" }} >PLEDGE ESTIMATED AMOUNT</h2>
                        <p style={{ fontSize: "13px", marginBottom: "0.1rem" }} className='text-success fw-bold'>KSH. <b>{parseInt(campaignStatement.in_kind_amount).toLocaleString('en-US')}</b></p>
                      </div>

                    </div>
                    :
                    <></>
                } */}



                <div className='d-flex justify-content-between ms-5'>
                  <Button className='btn btn-sm text-white float-end border-0 bg-dark fw-bold p-2 me-2 ReddemButtonWeb' onClick={handleOpenRedeemModal}>Redeem</Button>

                  <Button className='btn btn-sm text-white float-end border-0 btn-danger fw-bold p-2 me-2 ReddemButtonWeb' onClick={handleShow}>Share</Button>

                </div>


              </div>
            </Card.Body>
          </Card>

          <Card className='BottomDisplayCard'>
            <Card.Body>
              {campaignStatement.pledge_type === 'in-kind' ?
                <>
                  <p className='text-start'><b>Description of In-Kind pledge:</b></p>
                  <p className='text-start'>{campaignStatement.inkind_name}</p>
                </>
                :
                <></>
              }

              <Row>
                <Col md={9} style={{ textAlign: "left" }}>

                  <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                    {campaignStatement.pledge_type === 'monetary' ?

                      <Nav variant='tabs' className='d-flex'>
                        <Nav.Item style={{ width: '40%' }}>
                          <Nav.Link
                            className=''
                            eventKey='pledge'
                            style={{ fontSize: "14px" }}
                          >
                            Pledge Statements
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '40%' }}>
                          <Nav.Link
                            eventKey='contributor'
                            className=''
                            style={{ fontSize: "14px" }}

                          >
                            Contributors
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      :
                      <Nav variant='tabs' className='d-flex'>
                        <Nav.Item style={{ width: '40%' }}>
                          <Nav.Link
                            className=''
                            eventKey='pledge'
                            style={{ fontSize: "14px" }}
                          >
                            Pledge Statements
                          </Nav.Link>
                        </Nav.Item>

                      </Nav>

                    }


                    <Tab.Content>
                      <Tab.Pane eventKey='pledge'>
                        {displayedItems.length > 0 ?
                          <div className='tasks' >
                            <div className='d-flex justify-content-between'>

                              <div>
                                <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                              </div>

                              <Form className="searchBar" style={{ right: "0px" }}>
                                <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                              </Form>
                            </div>

                            <Table className='table'>
                              <thead>
                                <tr>
                                  <td>Name</td>
                                  <td>Transaction Date</td>
                                  <td>Amount</td>
                                  {campaignStatement.pledge_type === 'monetary' ?

                                    <td>Transaction Type</td>
                                    :
                                    <></>}
                                  <td>Status</td>
                                </tr>
                              </thead>
                              <tbody>
                                {displayedItems.map((pledge) => (
                                  <tr>
                                    <td>{pledge.first_name}</td>
                                    <td>{pledge.transcation_time}</td>
                                    {pledge.transcation_amount && (

                                      <td>
                                        {campaignStatement.pledge_type === 'monetary' ?

                                          <span>{pledge.transaction_currency}.</span>
                                          :
                                          <span> KSH.</span>}
                                        {pledge.transcation_amount.toLocaleString('en-US')}
                                      </td>

                                    )}
                                    {campaignStatement.pledge_type === 'monetary' ?

                                      <td style={{ width: "17vw" }}>
                                        {pledge.transcation_type === 'mpesa' ?
                                          <>
                                            <Image src={Mpesa} style={{ width: "15%", marginRight: "0.2rem" }} />
                                            <span>MPESA</span>
                                          </>
                                          : pledge.transcation_type === 'bank' ?
                                            <>
                                              <Image src={Bank} style={{ width: "15%", marginRight: "0.2rem" }} />
                                              <span>Bank</span>
                                            </>
                                            : pledge.transcation_type === 'cheque' ?
                                              <>
                                                <Image src={Cheque} style={{ width: "15%", marginRight: "0.2rem" }} />
                                                <span>Cheque</span>
                                              </>
                                              : pledge.transcation_type === 'cash' ?
                                                <>
                                                  <Image src={Cash} style={{ width: "15%", marginRight: "0.2rem" }} />
                                                  <span>Cash</span>
                                                </>
                                                : pledge.transcation_type === 'paypal' ?
                                                  <>
                                                    <Image src={PayPal} style={{ width: "15%", marginRight: "0.2rem" }} />
                                                    <span>PayPal</span>
                                                  </>
                                                  : pledge.transcation_type === 'card' ?
                                                    <>
                                                      <Image src={Credit} style={{ width: "15%", marginRight: "0.2rem" }} />
                                                      <span>Card</span>
                                                    </>
                                                    :
                                                    <>{pledge.transcation_type}</>
                                        }
                                      </td>
                                      :
                                      <></>}
                                    <td>
                                      {pledge.transaction_status === 'success' ?
                                        <span style={{ background: "#2ecc71", color: "white", padding: "0.5rem", borderRadius: "20px" }}>{pledge.transaction_status}</span>
                                        : pledge.transaction_status === 'rejected' ?
                                          <span style={{ background: "#9A1712", color: "white", padding: "0.5rem", borderRadius: "20px" }}>{pledge.transaction_status}</span>
                                          :
                                          <span style={{ background: "#f39c12", color: "white", padding: "0.5rem", borderRadius: "20px" }}>{pledge.transaction_status}</span>


                                      }
                                    </td>
                                  </tr>

                                ))}

                              </tbody>

                            </Table>
                            <div className="pagination">
                              <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                &lt;
                              </button>
                              <span>{`${startIndex + 1}-${endIndex} of ${pledgeStatement.length}`}</span>
                              <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                &gt;
                              </button>
                            </div>
                          </div>
                          :
                          <p>There are no pledge statements</p>
                        }
                      </Tab.Pane>

                      <Tab.Pane eventKey='contributor'>
                        {displayedItemsContributor.length > 0 ?
                          <div className='tasks' >
                            <div className='d-flex justify-content-between'>

                              <div>
                                <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVContributor}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                              </div>

                              <Form className="searchBar" style={{ right: "0px" }}>
                                <FormControl type="text" placeholder="Search" onChange={handleSearchContributor} />
                              </Form>
                            </div>

                            <Table className='table'>
                              <thead>
                                <tr>
                                  <td>Name</td>
                                  <td>Transaction Date</td>
                                  <td>Amount</td>


                                </tr>
                              </thead>
                              <tbody>
                                {displayedItemsContributor.map((contributor) => (
                                  <tr>
                                    <td>{contributor.contributor_name}</td>
                                    <td>{contributor.first_contribution}</td>
                                    <td>KSH.{contributor.total_contribution}</td>
                                  </tr>
                                ))}

                              </tbody>

                            </Table>
                            <div className="pagination">
                              <button onClick={handlePrevPageContributor} disabled={currentContributorPage === 0}>
                                &lt;
                              </button>
                              <span>{`${startIndexContributor + 1}-${endIndexContributor} of ${contributorStatement.length}`}</span>
                              <button onClick={handleNextPageContributor} disabled={currentContributorPage === totalPagesContributor - 1}>
                                &gt;
                              </button>
                            </div>
                          </div>

                          :
                          <p>There are no contributor statements</p>
                        }

                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>

                </Col>
                {campaignStatement.goalAmount === 0 ?
                  <></>
                  : campaignStatement.pledge_type === 'monetary' ?
                    <Col md={3}>
                      <div className='pieChartContainerView'>
                        <div className='pie-chart-container'>
                          <p className='fw-bold mt-1' style={{ marginBottom: "0.1rem" }}>Pledge Progress</p>
                          {goalAmount &&
                            (
                              <div className='d-flex justify-content-between' style={{ marginTop: "-0.5rem" }}>
                                <div>
                                  <p style={{ fontSize: "13px", fontWeight: "700" }}>Pledge Amount</p>
                                  <p style={{ fontSize: "13px", marginBottom: "0.1rem", marginTop: "-0.5rem" }} className='text-success fw-bold'>KSH. <b>{goalAmount.toLocaleString('en-US')}</b></p>
                                </div>

                                <div>
                                  <p style={{ fontSize: "13px", fontWeight: "700" }}>Pledge Balance</p>
                                  <p style={{ fontSize: "13px", marginBottom: "0.1rem", marginTop: "-0.5rem" }} className='text-danger fw-bold'>KSH. <b>{currentBalance.toLocaleString('en-US')}</b></p>
                                </div>
                              </div>

                            )}
                          <div className='pledgeCardChart'>
                            <PieChart
                              percentage={percentagePledge}
                            />
                          </div>

                        </div>




                      </div>
                    </Col>
                    :
                    campaignStatement.pledge_type === 'in-kind' && !campaignStatement.in_kind_amount ?
                      <></>
                      :
                      <Col md={3}>
                        <div className='pieChartContainerView'>
                          <div className='pie-chart-container'>
                            <p className='fw-bold mt-1' style={{ marginBottom: "0.1rem" }}>Pledge Progress</p>
                            {inkindAmount &&
                              (
                                <div className='d-flex justify-content-between' style={{ marginTop: "-0.5rem" }}>
                                  <div>
                                    <p style={{ fontSize: "13px", fontWeight: "700" }}>Pledge Amount</p>
                                    <p style={{ fontSize: "13px", marginBottom: "0.1rem", marginTop: "-0.5rem" }} className='text-success fw-bold'>KSH. <b>{parseInt(inkindAmount).toLocaleString('en-US')}</b></p>
                                  </div>

                                  <div>
                                    <p style={{ fontSize: "13px", fontWeight: "700" }}>Pledge Balance</p>
                                    <p style={{ fontSize: "13px", marginBottom: "0.1rem", marginTop: "-0.5rem" }} className='text-danger fw-bold'>KSH. <b>{parseInt(inkindAmount).toLocaleString('en-US')}</b></p>
                                  </div>
                                </div>

                              )}

                            <PieChart
                              percentage={percentagePledgeInkind}
                            />
                          </div>




                        </div>
                      </Col>
                }

              </Row>
            </Card.Body>
          </Card>
        </Container>


        {/* modal for sharing */}
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='text-danger fw-bold m-auto'>SHARE THIS PLEDGE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex">
              <div
                className="text-decoration-none text-dark w-50"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handlePlatform("whatsapp")}
              >
                <Col
                  md={6}
                  className="d-flex shareButtons"
                  style={{
                    border: "1px solid #9a1712",
                    width: "98%",
                    marginInline: "0.5rem",
                    marginTop: "1rem",
                    borderRadius: "10px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <Image
                    src={Whatsapp}
                    className="mb-2 me-2 mt-2 ms-2"
                    style={{
                      borderRadius: "10px",
                      width: "auto",
                      height: "5vh",
                    }}
                  />
                  <p className="mt-3">Share on WhatsApp</p>
                </Col>
              </div>

              <div
                className="text-decoration-none text-dark w-50"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handlePlatform("facebook")}
              >
                <Col
                  md={6}
                  className="d-flex shareButtons"
                  style={{
                    border: "1px solid #9a1712",
                    width: "91%",
                    marginInline: "0.5rem",
                    marginTop: "1rem",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                  onClick={() => shareFacebook()}
                >
                  <Image
                    src={Facebook}
                    className="mb-2 me-2 mt-2 ms-2"
                    style={{
                      borderRadius: "10px",
                      width: "auto",
                      height: "5vh",
                    }}
                  />
                  <p className="mt-3">Share on Facebook</p>
                </Col>
              </div>
            </Row>

            <Row className="d-flex">
              <div
                className="text-decoration-none text-dark w-50"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handlePlatform("gmail")}
              >
                <Col
                  md={6}
                  className="d-flex shareButtons"
                  style={{
                    border: "1px solid #9a1712",
                    width: "91%",
                    marginInline: "0.5rem",
                    marginTop: "1rem",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <Image
                    src={Mail}
                    className="mb-2 me-2 mt-2 ms-2"
                    style={{
                      borderRadius: "10px",
                      width: "auto",
                      height: "5vh",
                    }}
                  />
                  <p className="mt-3">Share on Gmail</p>
                </Col>
              </div>

              <div
                className="text-decoration-none text-dark w-50"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handlePlatform("x")}
              >
                <Col
                  md={6}
                  className="d-flex shareButtons"
                  style={{
                    border: "1px solid #9a1712",
                    width: "91%",
                    marginInline: "0.5rem",
                    marginTop: "1rem",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <Image
                    src={Twitter}
                    className="mb-2 me-2 mt-2 ms-2"
                    style={{
                      borderRadius: "10px",
                      width: "auto",
                      height: "5vh",
                    }}
                  />
                  <p className="mt-3">Share on Twitter</p>
                </Col>
              </div>
            </Row>

            <Row className="d-flex">
              <div
                className="text-decoration-none text-dark w-50"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handlePlatform("instagram")}
              >
                <Col
                  md={6}
                  className="d-flex shareButtons"
                  style={{
                    border: "1px solid #9a1712",
                    width: "91%",
                    marginInline: "0.5rem",
                    marginTop: "1rem",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <Image
                    src={Instagram}
                    className="mb-2 me-2 mt-2 ms-2"
                    style={{
                      borderRadius: "10px",
                      width: "auto",
                      height: "5vh",
                    }}
                  />
                  <p className="mt-3">Share on Instagram</p>
                </Col>
              </div>

              <Col
                className='shareButtons'
                md={6}
                style={{
                  border: "1px solid #9a1712",
                  width: "44%",
                  marginInline: "1.1rem",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#8d0f0f2b")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
                onClick={() => handlePlatform("copy_link")}
              >
                <div className="d-flex">
                  <Image
                    src={Paperclip}
                    className="mb-2 me-2 mt-2"
                    style={{
                      borderRadius: "10px",
                      width: "auto",
                      height: "5vh",
                    }}
                  />
                  <p className="mt-3">Copy the link </p>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* modal to show the data for mpesa and staff */}

        <Modal size='lg' show={modalShow} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title className='text-danger fw-bold'>Make Redemption</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {campaignStatement.pledge_type === 'monetary' ?

              <>
                <h6 style={{ textTransform: "capitalize", placeContent: "center", display: "flex" }}>{campaignStatement.campaign_description}</h6>
                <p style={{ fontSize: "14px", fontWeight: "600", marginBottom: "0.5rem", textAlign: "left" }}>Select Mode Of Payment</p>

                <div className='d-flex flex-wrap' style={{ alignItems: "center" }}>

                  <div className='mt-2 d-flex '>
                    <div className={'ModeofPayment' + (selectedPaymentMethod === 'MPESA' ? ' selectedPayment' : '')} onClick={() => handleShowModal('MPESA')}>
                      <Image src={Mpesa} />
                      <p>MPESA</p>
                    </div>

                    <div className={'ModeofPayment' + (selectedPaymentMethod === 'CHEQUE' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CHEQUE')}>
                      <Image src={Cheque} />
                      <p>CHEQUE</p>
                    </div>

                    <div className={'ModeofPayment' + (selectedPaymentMethod === 'CARD' ? ' selectedPayment' : '')} onClick={() => handleShowModal('CARD')}>
                      <Image src={Credit} />
                      <p>CARD</p>
                    </div>

                    <div className={'ModeofPayment' + (selectedPaymentMethod === 'PAYPAL' ? ' selectedPayment' : '')} onClick={() => handleShowModal('PAYPAL')}>
                      <Image src={PayPal} />
                      <p>PAYPAL</p>
                    </div>
                    <div className={'ModeofPayment' + (selectedPaymentMethod === 'BANK TRANSFER' ? ' selectedPayment' : '')} onClick={() => handleShowModal('BANK TRANSFER')}>
                      <Image src={Bank} />
                      <p>BANK TRANSFER</p>
                    </div>






                  </div>


                </div>

                {selectedPaymentMethod === 'MPESA' && (
                  // Content for MPESA
                  <>

                    <div className='donation p-2'>
                      <div className='d-flex' style={{ placeContent: "center", width: "24vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                        <p className='kshP'>KSH</p>
                        <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "10rem", backgroundColor: "transparent", border: "0", outline: "0" }} />

                      </div>

                      <div className='d-flex flex-wrap'>
                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                          <Button
                            key={index}
                            className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                            onClick={() => handleButtonClick(index, amount)}
                            onChange={handleAmountChange}

                          >
                            {chequeData.currency}. {amount.toLocaleString()}
                          </Button>
                        ))}
                      </div>
                      <label>Phone Number</label>
                      {/* <div className='d-flex justify-content-between'>
                        <input className='input-field w-50' type='text'
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}

                          placeholder='Phone Number' style={{ fontSize: "13px" }} />

                        {phoneNumberOther.length > 0 ? (
                          // <Button className='btn btn-sm text-danger bg-transparent border-danger' style={{ height: "6vh", marginTop: "-0.5rem" }}>Choose from my numbers</Button>
                          <div style={{ position: 'relative' }}>
                            {!showOptions ? (
                              <Button className='btn btn-sm text-danger bg-transparent border-danger' style={{ height: "6vh", marginTop: "-0.5rem" }} onClick={handleShowOptions}>
                                Choose from my numbers
                              </Button>
                            ) : (
                              <select className="form-select" onChange={(e) => handleSelectPhoneNumber(e.target.value)}>
                                <option value="">Select a number</option>
                                {phoneNumberOptions}
                              </select>
                            )}
                          </div>


                        ) : (
                          phoneNumber === userPhoneNumber ? (
                            <></>
                          ) : (
                            phoneNumber && /^(\+254|254|0)\d{9}$/.test(phoneNumber) || !phoneNumberOther.map(entry => entry.phone_number === phoneNumber) ? (
                              <Button className='btn btn-sm text-danger bg-transparent border-danger' style={{ height: "6vh", marginTop: "-0.5rem" }} onClick={handleShowModalNumber}>
                                Add to my numbers
                              </Button>
                            ) : (
                              <></>
                            )

                          )
                        )}
                      </div> */}


                      <div className='d-flex justify-content-between' style={{ width: "80%" }}>
                        <input className='input-field w-25' type='text'
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder='Phone Number' style={{ fontSize: "13px" }} />

                        {phoneNumber === userPhoneNumber ? (
                          <></>
                        ) : (
                          !phoneNumberOther.some(entry => entry.phone_number === phoneNumber) && /^(\+254|254|0)\d{9}$/.test(phoneNumber) ? (
                            <Button className='btn btn-sm text-danger bg-transparent border-danger' style={{ height: "6vh", marginTop: "-0.5rem" }} onClick={handleShowModalNumber}>
                              Add to my numbers
                            </Button>
                          ) : (
                            phoneNumberOther.length > 0 ? (
                              <div style={{ position: 'relative' }}>
                                {!showOptions ? (
                                  <Button className='btn btn-sm text-danger bg-transparent border-danger' style={{ height: "6vh", marginTop: "-0.5rem" }} onClick={handleShowOptions}>
                                    Choose from my numbers
                                  </Button>
                                ) : (
                                  <select className="form-select" onChange={(e) => handleSelectPhoneNumber(e.target.value)}>
                                    <option value="">Select a number</option>
                                    {phoneNumberOptions}
                                  </select>
                                )}
                              </div>
                            ) : (
                              <></>
                            )
                          )
                        )}
                      </div>
                      {isLoading ?
                        <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-4 redeemMpesa' type='submit'><Spinner /></Button>
                        :
                        <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-4 redeemMpesa' onClick={handleRedeemAdd}>Redeem</Button>

                      }

                    </div>
                  </>
                )}
                {selectedPaymentMethod === 'CHEQUE' && (
                  // Content for CHEQUE
                  <>
                    <div className='donation p-0'>
                      <p className='fw-bold mt-1' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'> *</b></p>

                      <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                        <select value={chequeData.currency}
                          onChange={handlePledgeInput}
                          className='currency'
                          style={{ border: "0", color: "#9A1712" }}
                          name='currency' required>
                          <option className='kshP' value='KSH'>KSH</option>
                          <option className='kshP' value='USD'>USD</option>
                          <option className='kshP' value='EUR'>EUR</option>
                          <option className='kshP' value='GBP'>GBP</option>
                        </select>
                        {donationAmount && (
                          <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                        )}

                      </div>

                      <div className='d-flex flex-wrap'>
                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                          <Button
                            key={index}
                            className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                            onClick={() => handleButtonClick(index, amount)}
                            onChange={handleAmountChange}

                          >
                            {chequeData.currency}. {amount.toLocaleString()}
                          </Button>
                        ))}
                      </div>


                    </div>


                    <p className='fw-bold' style={{ fontSize: "12px" }}>Cheque Number <b className='text-danger'> *</b></p>
                    <input
                      className='chequeNumber'
                      style={{ width: "35vw" }}
                      type='text'
                      placeholder='Cheque Number'
                      onChange={handlePledgeInput}
                      name='chequeNumber'
                      required
                    />

                    <p className='fw-bold' style={{ fontSize: "12px" }}>Image Of Cheque(optional)</p>
                    <Button style={{ fontSize: "14px" }} className='btn btn-sm bg-transparent text-danger border-danger' onClick={() => document.getElementById('imageUpload').click()}>Add cheque photo</Button>

                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleChangeImage}
                    />
                    {images && images instanceof File && (
                      <>
                        <img
                          src={URL.createObjectURL(images)}
                          alt=""
                          className=""
                          style={{ width: '20vw', height: '20vh', margin: "0 auto", display: "block" }}
                        />

                      </>
                    )}


                    <div>
                      <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'> *</b></p>
                      <input
                        className='chequeNumber'
                        onChange={handlePledgeInput}
                        value={chequeData.transaction_date}
                        type='date'
                        name='transaction_date'
                        placeholder='Payment Date'
                        max={new Date().toISOString().slice(0, 10)}
                        required
                      />

                    </div>

                  </>
                )}
                {selectedPaymentMethod === 'CARD' && (
                  // Content for CARD
                  <>
                    <div className='donation p-0'>
                      <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                      <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                        <select value={chequeData.currency}
                          onChange={handlePledgeInput}
                          className='currency'
                          style={{ border: "0", color: "#9A1712" }}
                          name='currency'>
                          <option className='kshP' value='KSH'>KSH</option>
                          <option className='kshP' value='USD'>USD</option>
                          <option className='kshP' value='EUR'>EUR</option>
                          <option className='kshP' value='GBP'>GBP</option>
                        </select>
                        {donationAmount && (
                          <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                        )}

                      </div>

                      <div className='d-flex flex-wrap'>
                        {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                          <Button
                            key={index}
                            className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                            onClick={() => handleButtonClick(index, amount)}
                            onChange={handleAmountChange}

                          >
                            {chequeData.currency}. {amount.toLocaleString()}
                          </Button>
                        ))}
                      </div>


                    </div>


                    <p className='fw-bold' style={{ fontSize: "12px" }}>Reference Number <b className='text-danger'>*</b></p>
                    <input
                      className='chequeNumber'
                      style={{ width: "35vw" }}
                      type='text'
                      placeholder='Reference Number'
                      onChange={handlePledgeInput}
                      name='chequeNumber'
                      value={chequeData.chequeNumber}

                    />




                    <div>
                      <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                      <input
                        className='chequeNumber'
                        onChange={handlePledgeInput}
                        value={chequeData.transaction_date}
                        type='date'
                        name='transaction_date'
                        placeholder='Payment Date'
                        max={new Date().toISOString().slice(0, 10)}
                      />

                    </div>

                  </>

                )}
                {selectedPaymentMethod === 'PAYPAL' && (
                  // Content for PAYPAL
                  <p className='text-center mt-3 text-danger fw-bold' style={{ fontSize: "20px" }}>COMING SOON</p>
                )}
                {selectedPaymentMethod === 'BANK TRANSFER' && (
                  // Content for BANK TRANSFER
                  <>
                    <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Select type of bank transer <b className='text-danger'>*</b></p>

                    {/* <div className='mt-3 d-flex justify-content-between'>

                      <div className={'ModeofPayment' + (bankTransferOption === 'PESALINK' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('PESALINK')}>
                        <Image src={Bank} style={{ width: "12%" }} />
                        <p>Pesalink</p>
                      </div>
                      <div className={'ModeofPayment' + (bankTransferOption === 'EFT' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('EFT')}>
                        <Image src={Bank} style={{ width: "12%" }} />
                        <p>EFT</p>
                      </div>
                      <div className={'ModeofPayment' + (bankTransferOption === 'RTGS' ? ' selectedPayment' : '')} onClick={() => handleBankTransferOptionChange('RTGS')}>
                        <Image src={Bank} style={{ width: "12%" }} />
                        <p>RTGS</p>
                      </div>

                    </div> */}
                    <div className='mt-3'>
                      <select
                        className='form-select w-50'
                        value={bankTransferOption}
                        onChange={(e) => handleBankTransferOptionChange(e.target.value)}
                        style={{ fontSize: "12px" }}
                      >
                        <option value='PESALINK'>Pesalink</option>
                        <option value='EFT'>EFT</option>
                        <option value='RTGS'>RTGS</option>
                      </select>
                    </div>


                    <>
                      <div className='donation p-0' style={{ width: "65vw" }}>
                        <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Input Amount <b className='text-danger'>*</b></p>

                        <div className='d-flex' style={{ placeContent: "center", width: "20vw", margin: "0 auto", borderBottom: "1.5px solid #9A1712" }}>
                          <select value={chequeData.currency}
                            onChange={handlePledgeInput}
                            className='currency'
                            style={{ border: "0", color: "#9A1712" }}
                            name='currency'>
                            <option className='kshP' value='KSH'>KSH</option>
                            <option className='kshP' value='USD'>USD</option>
                            <option className='kshP' value='EUR'>EUR</option>
                            <option className='kshP' value='GBP'>GBP</option>
                          </select>
                          {donationAmount && (
                            <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                          )}
                        </div>

                        <div className='d-flex flex-wrap'>
                          {[50, 100, 500, 1000, 2500, 5000].map((amount, index) => (

                            <Button
                              key={index}
                              className={`btn btn-sm custom-button p-2 me-2 ${activeIndex === index ? 'btn-danger text-white active' : 'bg-secondary bg-opacity-25 border-secondary text-dark'}`}
                              onClick={() => handleButtonClick(index, amount)}
                              onChange={handleAmountChange}

                            >
                              {chequeData.currency}. {amount.toLocaleString()}
                            </Button>
                          ))}
                        </div>


                      </div>
                      <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Bank <span className='text-danger'>*</span></p>
                      <div style={{ width: "35vw" }}>
                        <Select
                          options={optionList}
                          placeholder="Select a bank"
                          value={selectedBank}
                          onChange={handleSelectChange}
                          style={{ padding: "0", fontSize: "10px" }}
                          required
                        />

                      </div>



                      <p className='fw-bold mt-2' style={{ fontSize: "12px" }}>Transaction Number(Optional) <b className='text-danger'>*</b></p>
                      <input
                        className='chequeNumber'
                        style={{ width: "35vw" }}
                        type='text'
                        placeholder='Transaction Number'
                        onChange={handlePledgeInput}
                        value={chequeData.chequeNumber}
                        name='chequeNumber'
                      />

                      <div>
                        <p className='fw-bold' style={{ fontSize: "12px" }}>Date <b className='text-danger'>*</b></p>
                        <input
                          className='chequeNumber'
                          onChange={handlePledgeInput}
                          value={chequeData.transaction_date}
                          type='date'
                          name='transaction_date'
                          placeholder='Payment Date'
                          max={new Date().toISOString().slice(0, 10)}
                        />

                      </div>

                    </>
                  </>

                )}


                {selectedPaymentMethod === 'PAYPAL' || selectedPaymentMethod === null ? null :
                  selectedPaymentMethod && (
                    <>
                      <p className='text-info mt-3' style={{ textTransform: "capitalize", fontSize: "11px", marginInline: "3rem" }} onClick={handleShow}>
                        Tap to share a link to another person to contribute for you
                      </p>

                      {selectedPaymentMethod === 'MPESA' ? (
                        <>

                          <div style={{ borderTop: "2px solid grey", marginBottom: "5rem" }}>
                            <p className='text-danger fw-bold mt-2'>Alternative M-PESA Payment Steps:</p>
                            <ol>
                              <li>Launch SIM Toolkit or M-PESA App</li>
                              <li>Select Paybill</li>
                              <li>Enter the Business Number: <b>5141411</b></li>
                              <li>Enter the Account Number: <b>{id}</b></li>
                              <li>Enter Amount and complete the transaction using your M-PESA PIN</li>
                            </ol>
                          </div>
                        </>
                      ) : selectedPaymentMethod === 'BANK TRANSFER' || selectedPaymentMethod === 'CHEQUE' ?
                        <div style={{ borderTop: "2px solid grey", marginBottom: "5rem" }}>
                          <p className='text-danger fw-bold mt-2'>Alternative Bank transfer Payment Steps:</p>
                          <ol>
                            <li>Bank: Stanbic Bank</li>
                            <li>Branch: Westlands</li>
                            <li>Account Name: Christ Is The Answer Ministries</li>
                            <li>Account Number: 0100000496426</li>
                            <li>SWIFT CODE: <b>SBICKENX</b></li>

                          </ol>
                        </div>
                        :


                        (
                          <>
                          </>
                        )}


                    </>
                  )
                }


              </>
              :
              <>
                <p>Describe the In-Kind contribution</p>
                <textarea
                  rows={3}
                  onChange={handlePledgeInput}
                  value={inKindData.description}
                  placeholder='Enter description'
                  className='border-danger p-2 w-100'
                  style={{ borderRadius: "10px" }}
                  name='description'

                />
                <p>Enter estimated value of In-Kind contribution <b className='text-danger'>*</b></p>
                <div className='d-flex' style={{ placeContent: "center", width: "20vw", borderBottom: "1.5px solid #9A1712" }}>
                  <select value={chequeData.currency}
                    onChange={handlePledgeInput}
                    className='currency'
                    style={{ border: "0", color: "#9A1712" }}
                    required
                    name='currency'>
                    <option className='kshP' value='KSH'>KSH</option>
                    <option className='kshP' value='USD'>USD</option>
                    <option className='kshP' value='EUR'>EUR</option>
                    <option className='kshP' value='GBP'>GBP</option>
                  </select>
                  {donationAmount && (
                    <input value={donationAmount.toLocaleString()} onChange={(e) => setDonationAmount(e.target.value)} style={{ paddingLeft: "0rem", backgroundColor: "transparent", border: "0", outline: "0", width: "15vw" }} />

                  )}
                </div>
              </>


            }

          </Modal.Body>
          {selectedPaymentMethod !== 'MPESA' ?
            <Modal.Footer>
              {isLoading ?
                <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-4 w-25' ><Spinner /></Button>

                :
                <Button className='btn btn-sm btn-danger fw-bold text-white p-2 me-4 w-25' onClick={handleRedeemAdd}>Redeem</Button>

              }

            </Modal.Footer>
            :
            <></>
          }

        </Modal>

        {/* modal for editing pledge */}
        <Modal show={editPLedge} onHide={handleEdiPledgeClose} centered >
          <Modal.Header closeButton>
            <Modal.Title className='text-danger fw-bold'>Edit Pledge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {campaignStatement.pledge_type === 'monetary' ?
              <>

                <Form onSubmit={changePledge}>
                  {campaignStatement.pledge_frequency ?
                    <>
                      <label>Choose another frequency</label>
                      <br></br>
                      <FormSelect value={pledgeDataEdit.pledgeFrequency} onChange={handleInputChange} className='border-danger w-100 p-2 mb-1' name="pledgeFrequency" style={{ borderRadius: "6px" }}>
                        {pledgeDataEdit.pledgeFrequency && (
                          <option value="" style={{ textTransform: "capitalize" }}>{pledgeDataEdit.pledgeFrequency}</option>
                        )}

                        {pledgeDataEdit.pledgeFrequency !== 'weekly' && (
                          <option value='weekly'>Every Week</option>
                        )}
                        {pledgeDataEdit.pledgeFrequency !== '2weeks' && (
                          <option value='2weeks'>Every 2 Weeks</option>
                        )}
                        {pledgeDataEdit.pledgeFrequency !== 'monthly' && (
                          <option value='monthly'>Every Month</option>
                        )}
                        {pledgeDataEdit.pledgeFrequency !== '3months' && (
                          <option value='3months'>Every 3 Months</option>
                        )}
                        {pledgeDataEdit.pledgeFrequency !== '6months' && (
                          <option value='6months'>Every 6 Months</option>
                        )}
                        {pledgeDataEdit.pledgeFrequency !== 'yearly' && (
                          <option value='yearly'>Every Year</option>
                        )}
                      </FormSelect>
                    </>

                    :
                    <></>
                  }

                  <div>
                    <label>Pledge Amount:</label>
                    <div className='d-flex'>
                      <select value={pledgeDataEdit.currency}
                        onChange={handlePledgeInput}
                        className='kshInput EditKshDisplay me-2 border-danger'

                        name='currency'>
                        <option value='KSH'>KSH</option>
                        <option value='USD'>USD</option>
                        <option value='EUR'>EUR</option>
                        <option value='GBP'>GBP</option>
                      </select>

                      <input
                        type="number"
                        id="originalAmount"
                        name="originalAmount"
                        className='input-field'
                        value={pledgeDataEdit.originalAmount}
                        onChange={handleInputChange}
                      />
                    </div>


                  </div>
                  {isLoading ?
                    <Button className='btn btn-danger btn-sm' type=''><Spinner /></Button>
                    :
                    <Button className='btn btn-danger btn-sm' type='submit'>Save Changes</Button>
                  }


                </Form>
              </>
              :
              <>
                <Form onSubmit={changePledge}>
                  <div>
                    <label>In-kind Description:</label>
                    <textarea
                      type="text"
                      id="inkindName"
                      rows={4}
                      name="inkindName"
                      className='input-field border-danger p-2 w-100'
                      style={{ borderRadius: "10px" }}
                      value={pledgeDataEdit.inkindName}
                      onChange={handleInputChange}
                    />

                  </div>
                  <div className=''>
                    <label htmlFor="originalPledgeAmount">Estimated Pledge Amount:</label>


                    <input
                      type="number"
                      id="originalPledgeAmount"
                      name="originalPledgeAmount"
                      className='input-field'
                      value={pledgeDataEdit.originalPledgeAmount}
                      onChange={handleInputChange}
                    />
                  </div>
                  {isLoading ?
                    <Button className='btn btn-danger btn-sm' type='submit'><Spinner /></Button>
                    :
                    <Button className='btn btn-danger btn-sm' type='submit'>Save Changes</Button>
                  }
                </Form>
              </>
            }
          </Modal.Body>
        </Modal>


        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title >
              <h5 className='text-danger'>Confirm Number Addition</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p>Are you sure you want to add the number {phoneNumber} to your numbers?            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            {isLoading ?
              <Button variant="danger">
                <Spinner />              </Button>
              :
              <Button variant="danger" onClick={handleAddNumber}>
                Confirm
              </Button>}

          </Modal.Footer>
        </Modal>
      </div>

      {/* on the mobile view */}
      <div className='pledeDisplayMobile'>
        <div className='d-flex' style={{ position: "sticky", top: "8%", justifyContent: "right" }}>
          <Button className='btn btn-sm btn-dark bg-dark text-white' onClick={handleEdiPledge}>Edit Pledge</Button>
          {campaignStatement.pledge_frequency ?

            <Button className='btn btn-sm btn-dark bg-dark text-white ms-3' onClick={showScheduleOpen}>View Schedule</Button>
            :
            <></>
          }

        </div>

        {/* <div style={{ position: "sticky", top: "8%", right: "0", zIndex: 100 }}>
    <div className='d-flex flex-column'>
        <Button className='btn btn-sm btn-dark bg-dark text-white mb-3' onClick={handleEdiPledge}>Edit Pledge</Button>
        {campaignStatement.pledge_frequency &&
            <Button className='btn btn-sm btn-dark bg-dark text-white' onClick={showScheduleOpen}>View Schedule</Button>
        }
    </div>
</div> */}




        <div class="imageContainer">
          <img src={image} alt="Placeholder" className='TopDisplayImage' />

        </div>

        <div>
          <p onClick={goBack}>
            <i className='bi bi-chevron-left TopRightArrow'></i>
          </p>


        </div>
        {/* <div className='opacityImage'></div> */}

        <div className="campaign-info">


          <div className="campaign-header-mobile" >
            <div className="campaign-header-mobile" style={{ marginTop: "-4.4rem" }}>
              <h5 onClick={handleCopyLinkCode} style={{ fontSize: "12px" }}>Code: <span style={{ color: "#8e44ad" }}>{id}</span></h5>

            </div>


            <h5 style={{ fontSize: "18px" }}>{title}</h5>
            <div className="d-flex align-items-center">
              <Image src={campaignStatement.user_photo} className='profileImage' />
              <div className="donate-button">{campaignStatement.user_name}</div>
            </div>
          </div>
          {campaignStatement.pledge_type === 'monetary' ?

            <Card className="campaign-details">
              <Card.Body>
                <div className='d-flex justify-content-between' style={{ marginTop: "-10px" }}>
                  <div className="my-pledge">My Pledge</div>
                  <div className="my-pledge" style={{ color: fillMobileColor }}>Contributed</div>

                </div>
                <div className='d-flex justify-content-between'>

                  <span className="amount fw-bold">KSH. {goalAmount.toLocaleString('en-US')}</span>
                  {raisedAmount &&
                    <span className="amount fw-bold" style={{ color: fillMobileColor }}>KSH. {raisedAmount.toLocaleString('en-US')}</span>

                  }
                </div>

                <div className="progress">
                  <div className={`progress-fill`} style={{ width: `${percentagePledge}%`, backgroundColor: fillColorClass }} />
                </div>

                <div className='d-flex justify-content-between'>
                  <div className="days-left"><i className='bi bi-clock text-danger me-1'></i>{monthsToFutureDate} month(s) to go</div>

                  <div className="days-left">
                    <Image src={Coin} className='me-1' style={{ width: "12%" }} />
                    {contributions} contributions</div>
                </div>

                <div className='d-flex mt-2'>
                  {campaignStatement.pledge_type === 'monetary' ?
                    <span className='text-white' style={{ fontSize: "9px", textTransform: "capitalize", padding: "0.1rem", borderRadius: "3px", backgroundColor: "#2ecc71" }}>{campaignStatement.pledge_type}</span>
                    :
                    <span className='text-white' style={{ fontSize: "9px", textTransform: "capitalize", padding: "0.1rem", borderRadius: "3px", backgroundColor: "#3498db" }}>{campaignStatement.pledge_type}</span>


                  }
                  {campaignStatement.pledge_frequency ?
                    <span className='text-white ms-1' style={{ fontSize: "9px", padding: "0.1rem", borderRadius: "3px", backgroundColor: "grey" }}>{campaignStatement.pledge_frequency}</span>
                    :
                    <span className='text-white ms-1' style={{ fontSize: "9px", padding: "0.1rem", borderRadius: "3px", backgroundColor: "grey" }}>One Off</span>

                  }

                </div>
              </Card.Body>
            </Card>
            :
            <></>}

          {/* the statements */}
          <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>

            {campaignStatement.pledge_type === 'monetary' ?

              <Nav variant='tabs' className='d-flex'>
                <Nav.Item style={{ width: '40%' }}>
                  <Nav.Link
                    className=''
                    eventKey='pledge'
                    style={{ fontSize: "14px" }}
                  >
                    Statements
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ width: '40%' }}>
                  <Nav.Link
                    eventKey='contributor'
                    className=''
                    style={{ fontSize: "14px" }}

                  >
                    Contributors
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              :
              <Nav variant='tabs' className='d-flex'>
                <Nav.Item style={{ width: '60%' }}>
                  <Nav.Link
                    className=''
                    eventKey='pledge'
                    style={{ fontSize: "14px" }}
                  >
                    Pledge Statements
                  </Nav.Link>
                </Nav.Item>

              </Nav>

            }
            <Tab.Content>
              <Tab.Pane eventKey='pledge'>
                {displayedItems.length > 0 ?
                  <div className='tasks' >
                    <div className='d-flex justify-content-end'>
                      {/* <Button className='btn btn-dark btn-sm text-start text-white me-3' style={{ width: "23vw", height: "4vh" }} onClick={exportCSV}> Export <Image src={Excel} style={{ width: "25%" }} /></Button> */}
                      <Button className='btn btn-danger btn-sm text-start text-white me-3' style={{ width: "23vw", height: "4vh" }} onClick={exportCSV}> Export <Image src={Pdf} style={{ width: "25%" }} /></Button>
                    </div>
                    {displayedItems.map((statement) => (
                      <>

                        <div className='pledgeDataStatement'>

                          <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                              <Image src={statement.user_image} style={{ borderRadius: "50%", width: "10vw", height: "5vh", marginRight: "0.5rem" }} />

                              <div className='text-start align-self-center'>
                                <p style={{ marginBottom: "0" }}>{statement.first_name}</p>
                                <span className='text-muted'>{statement.transcation_time}</span>
                              </div>
                            </div>
                            <div>
                              {campaignStatement.pledge_type === 'monetary' ?

                                <p className='fw-bold' style={{ marginBottom: "0" }}>{statement.transaction_currency}. {statement.transcation_amount}</p>
                                :
                                <p className='fw-bold' style={{ marginBottom: "0" }}>KSH. {statement.transcation_amount}</p>
                              }
                            </div>

                          </div>

                          {/* below data display */}
                          <div className='d-flex justify-content-between mt-3 mb-2'>
                            <p style={{ fontSize: "11px" }}>Status:
                              {statement.transaction_status === 'success' ?
                                <span className='statusPledge' style={{ background: "#2ecc71" }}>{statement.transaction_status}</span>
                                : statement.transaction_status === 'rejected' ?
                                  <span className='statusPledge' style={{ background: "#9A1712" }}>{statement.transaction_status}</span>
                                  :
                                  <span className='statusPledge' style={{ background: "#f39c12" }}>{statement.transaction_status}</span>


                              }

                            </p>

                            {campaignStatement.pledge_type === 'monetary' ?

                              <p style={{ fontSize: "11px", width: "40%" }}>Payment Mode:


                                {statement.transcation_type === 'mpesa' ?
                                  <>
                                    <Image src={Mpesa} style={{ width: "12%", marginRight: "0.2rem" }} />
                                    <span>MPESA</span>
                                  </>
                                  : statement.transcation_type === 'bank' ?
                                    <>
                                      <Image src={Bank} style={{ width: "12%", marginRight: "0.2rem" }} />
                                      <span>Bank</span>
                                    </>
                                    : statement.transcation_type === 'cheque' ?
                                      <>
                                        <Image src={Cheque} style={{ width: "12%", marginRight: "0.2rem" }} />
                                        <span>Cheque</span>
                                      </>
                                      : statement.transcation_type === 'cash' ?
                                        <>
                                          <Image src={Cash} style={{ width: "12%", marginRight: "0.2rem" }} />
                                          <span>Cash</span>
                                        </>
                                        : statement.transcation_type === 'paypal' ?
                                          <>
                                            <Image src={PayPal} style={{ width: "12%", marginRight: "0.2rem" }} />
                                            <span>PayPal</span>
                                          </>
                                          : statement.transcation_type === 'card' ?
                                            <>
                                              <Image src={Credit} style={{ width: "12%", marginRight: "0.2rem" }} />
                                              <span>Card</span>

                                            </>
                                            :

                                            <></>
                                }

                              </p>
                              :
                              <></>}
                          </div>



                        </div>

                      </>

                    ))}

                    <div>

                    </div>
                    <div className="pagination">
                      <button onClick={handlePrevPage} disabled={currentPage === 0}>
                        &lt;
                      </button>
                      <span>{`${startIndex + 1}-${endIndex} of ${pledgeStatement.length}`}</span>
                      <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                        &gt;
                      </button>
                    </div>
                  </div>
                  :
                  <p className='text-muted' style={{ fontSize: "10px" }}>No Statements At The Moment Make A Payment First</p>
                }
              </Tab.Pane>

              <Tab.Pane eventKey='contributor'>
                {displayedItemsContributor.length > 0 ?
                  <div className='tasks' >
                    <div className='d-flex justify-content-between'>

                      <div>
                        <Button className='btn btn-dark btn-sm text-start text-white me-3' onClick={exportCSV}> Export<br></br> Statements (Excel)</Button>
                      </div> <div>
                        <Button className='btn btn-danger btn-sm text-start text-white me-3' onClick={exportCSV}> Export <br></br>Statements (PDF)</Button>
                      </div>



                    </div>
                    {displayedItemsContributor.map((statement) => (
                      <>

                        <div className='pledgeDataStatement'>

                          <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                              <Image src={statement.user_image} style={{ borderRadius: "50%", width: "10vw", height: "5vh", marginRight: "0.5rem" }} />

                              <div className='text-start align-self-center'>
                                <p style={{ marginBottom: "0" }}>{statement.contributor_name}</p>
                                <span className='text-muted'>{statement.first_contribution}</span>
                              </div>
                            </div>
                            <div>
                              <p className='fw-bold' style={{ marginBottom: "0" }}>KSH. {statement.total_contribution}</p>
                            </div>

                          </div>



                        </div>

                      </>

                    ))}
                    <div className="pagination">
                      <button onClick={handlePrevPageContributor} disabled={currentContributorPage === 0}>
                        &lt;
                      </button>
                      <span>{`${startIndexContributor + 1}-${endIndexContributor} of ${contributorStatement.length}`}</span>
                      <button onClick={handleNextPageContributor} disabled={currentContributorPage === totalPagesContributor - 1}>
                        &gt;
                      </button>
                    </div>
                  </div>

                  :
                  <p>There are no contributor statements</p>
                }

              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          {/* modal for viewing the schedule */}
          <Modal show={showModalSchedule} onHide={showScheduleClose}>
            <Modal.Header closeButton>
              <Modal.Title className='text-danger fw-bold m-auto'>Redemption Schedule</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className='d-flex justify-content-between'>
                <Card className='d-flex topDisplayCard endDate'>
                  <Card.Body>
                    <i className='bi bi-calendar-week me-2'></i>
                    <div>
                      <p>Pledge End Date</p>
                      <span>

                        <PaymentCalendar

                          installmentAmount={campaignStatement.installment}
                          paymentFrequency={campaignStatement.pledge_frequency}
                          totalAmount={campaignStatement.original_pledge_amount}
                          startDate={campaignStatement.installment_start_date}
                          displayType='end_date'
                        />

                      </span>
                    </div>
                  </Card.Body>

                </Card>
                <Card className='d-flex topDisplayCard nextDate'>
                  <Card.Body>
                    <i className='bi bi-calendar2-check me-2'></i>
                    <div>
                      <p>Next Redemption Date</p>
                      <span>
                        <PaymentCalendar

                          installmentAmount={campaignStatement.installment}
                          paymentFrequency={campaignStatement.pledge_frequency}
                          totalAmount={campaignStatement.original_pledge_amount}
                          startDate={campaignStatement.installment_start_date}
                          displayType='next_payment_date'
                        />
                      </span>
                    </div>
                  </Card.Body>

                </Card>

                <Card className='d-flex topDisplayCard frequencyDate'>
                  <Card.Body>
                    <i className='bi bi-calendar-plus me-2'></i>
                    <div>
                      <p>Redemption frequency</p>
                      <span>
                        {campaignStatement.pledge_frequency}
                      </span>
                    </div>
                  </Card.Body>

                </Card>

              </div>


              <Card className='TopDisplayMetrics'>
                <Card.Body>
                  <div>
                    <span>Total</span>
                    {campaignStatement.original_pledge_amount &&
                      <p>KSH.{campaignStatement.original_pledge_amount.toLocaleString('en-US')}</p>

                    }
                  </div>

                  <div>
                    <span>Installments</span>
                    {campaignStatement.installment &&
                      <p>KSH.{parseInt(campaignStatement.installment).toLocaleString('en-US')}</p>

                    }
                  </div>

                  <div>
                    <span>Balance</span>
                    {campaignStatement.pledge_balance &&
                      <p>KSH.{campaignStatement.pledge_balance.toLocaleString('en-US')}</p>

                    }
                  </div>
                </Card.Body>

              </Card>

              <PaymentCalendar

                installmentAmount={campaignStatement.installment}
                paymentFrequency={campaignStatement.pledge_frequency}
                totalAmount={campaignStatement.original_pledge_amount}
                startDate={campaignStatement.installment_start_date}
                displayType='calendar'
              />

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={showScheduleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        </div>
        <div className="actions">
          <Link className='text-decoration-none text-white' to={`/donation/${id}`}>
            <button className="btn btn-danger text-white">Redeem</button>
          </Link>
          <button className="btn btn-transparent text-dark border-dark" onClick={handleShow}><i class="bi bi-share-fill me-2"></i>Share </button>
        </div>
      </div>
    </div>
  );
};

export default PledgeCard;
